import axios from "../utiles/axios.util";

function apiFindByBizNo(BIZ_NO) {
    return axios.get(`/api/v1/join/findByBizNo?BIZ_NO=${BIZ_NO}`);
}

function apiFindByEmail(EMAIL) {
    return axios.get(`/api/v1/join/findByEmail?EMAIL=${EMAIL}`);
}

function apiEmailAuth(emailAuth) {
    return axios.get(`/api/v1/join/emailAuth?emailAuth=${emailAuth}`);
}

function apiPostJoin(formData) {
    return axios.post("/api/v1/join", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

function apiPostJoinFindByPw(formData) {
    return axios.post("api/v1/join/findByPw", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

function apiPostJoinModifyPw(formData) {
    return axios.post("api/v1/join/modifyPw", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export {
    apiFindByBizNo,
    apiFindByEmail,
    apiEmailAuth,
    apiPostJoin,
    apiPostJoinFindByPw,
    apiPostJoinModifyPw,
};
