import axios from "../utiles/axios.util";

function apiGetAsOrderInsert(formData) {
    return axios.post("/api/v1/order/insert", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

function apiGetOrder(sdate, edate, is_status) {
    return axios.get(
        `/api/v1/order?sdate=${sdate}&edate=${edate}&IS_STATUS=${is_status}`
    );
}

function apiGetOrderSelect(ORDER_ID) {
    return axios.get(`/api/v1/order/select?ORDER_ID=${ORDER_ID}`);
}

function apiPostOrderUpdate(formData) {
    return axios.post("/api/v1/order/update", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export {
    apiGetAsOrderInsert,
    apiGetOrder,
    apiGetOrderSelect,
    apiPostOrderUpdate,
};
