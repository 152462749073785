import Login from "../view/Login.svelte";
import {asCartRoutes} from "./asCartRoutes";
import {asHomeRoutes} from "./asHomeRoutes";
import {asItemRoutes} from "./asItemRoutes";
import {asJoinRoutes} from "./asJoinRoutes";
import {asPasswordRoutes} from './asPasswordRoutes';
import {asQuestionRoutes} from './asQuestionRoutes';


export default {
    "/": Login,
    "/login": Login,
    ...asCartRoutes,
    ...asHomeRoutes,
    ...asItemRoutes,
    ...asJoinRoutes,
    ...asPasswordRoutes,
    ...asQuestionRoutes,
};