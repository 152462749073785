<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import * as api from "../../store/asCart.store";
    import * as orderApi from "../../store/asOrder.store";
    import Header from "../../components/Header.svelte";
    import WebSideMenu from "../../components/WebSideMenu.svelte";
    import { push } from "svelte-spa-router";

    const cart_id_list = Common.getCookie("orderList");
    let cart_list = [];

    let total_cate = 0;
    let total_cnt = 0;

    let name_value = "";
    let phone_value = "";
    let phone_this;
    let addr1_value = "";
    let addr2_value = "";
    let note_value = "";
    let pay_se_value = "Y";
    let bcnc_nm_value = "";

    let order_list = [];
    let qntt_list = [];

    let AS_CT_GRAD = "";

    onMount(() => {
        getPageData();
    });

    // ------------------------------ PC ------------------------------
    // 검색
    async function getPageData() {
        const response = await api.apiGetAsCartOrder(cart_id_list);

        cart_list = response.order_list;

        total_cate = cart_list.length;
        total_cnt = 0;

        for (let i = 0; i < cart_list.length; i++) {
            total_cnt += cart_list[i].qntt;
            order_list.push(cart_list[i].item_CODE);
            qntt_list.push(cart_list[i].qntt);
        }

        const member_info = response.member_info;

        addr1_value = member_info.bplc_ADRES_1;
        addr2_value = member_info.bplc_ADRES_2;
        name_value = member_info.bsn_CHARGER;
        phone_value = member_info.bsn_CHARGER_TELNO;
        AS_CT_GRAD = member_info.as_CT_GRAD;
        bcnc_nm_value = member_info.as_BCNC_NM;
    }

    // 우편번호 api
    function kakaopost() {
        new daum.Postcode({
            oncomplete: function (data) {
                addr1_value = data.address;
            },
        }).open();
    }

    // 주문 등록
    async function order() {
        const formData = {
            ORDER_LIST: order_list,
            QNTT_LIST: qntt_list,
            CART_ID_LIST: cart_id_list,
            NAME: name_value,
            PHONE: phone_value,
            ADDR_1: addr1_value,
            ADDR_2: addr2_value,
            NOTE: note_value.replaceAll(/(?:\r\n|\r|\n)/g, "<br>"),
            PAY_SE: pay_se_value,
            BCNC_NM: bcnc_nm_value,
            AS_CT_GRAD: AS_CT_GRAD,
        };

        if (Common.nullCheck(name_value, "name_m", "담당자명")) return;
        if (Common.nullCheck(phone_value, "phone_m", "담당자 연락처")) return;

        const regex = /^[0-9]+$/;
        if (!regex.test(phone_value)) {
            alert("담당자 연락처는 숫자만 입력해주세요.");
            phone_this.focus();
            return;
        }

        if (Common.nullCheck(addr1_value, "addr1", "주소")) return;

        if (await orderApi.apiGetAsOrderInsert(formData)) {
            push("/as_cart/cart_order_complete");
        }
    }
</script>

<Header />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<section class="main inner main-pad-40" style="position: relative;">
    <div class="mobile">
        <div class="cmn-title-left" style="margin-top: 20px;">
            <p>A/S 부품 주문서</p>
        </div>

        <div class="item-list">
            <ul style="padding-bottom: 0;" id="item-list_m">
                {#each cart_list as data}
                    <li class="cart-list">
                        <div>
                            <p class="item-nm">{data.item_AS_NM}</p>
                            <div class="item-dtl">
                                <div class="item-img">
                                    <img
                                        src={data.photo_FILE == null ||
                                        data.photo_FILE == ""
                                            ? "resource/img/img_item.png"
                                            : Common.imgCnv(data.photo_FILE)}
                                        alt=""
                                    />
                                </div>
                                <div class="item-info cart-item-info">
                                    <div class="item-info-wrap">
                                        <p class="info-nm">품목코드</p>
                                        <p class="item-value item-code">
                                            {data.item_CODE}
                                        </p>
                                    </div>
                                    <div class="item-info-wrap">
                                        <p class="info-nm">수량</p>
                                        <p class="item-value item-code">
                                            {data.qntt}개
                                        </p>
                                    </div>
                                    <div class="item-info-wrap">
                                        <p class="info-nm">규격</p>
                                        <p class="item-value item-stndrd">
                                            {data.stndrd == null
                                                ? ""
                                                : data.stndrd}
                                        </p>
                                    </div>
                                    <div class="item-info-wrap">
                                        <p class="info-nm">중분류</p>
                                        <p class="item-value item-mclas">
                                            {data.mlsfc_NM == null
                                                ? ""
                                                : data.mlsfc_NM}
                                        </p>
                                    </div>
                                    <div class="item-info-wrap">
                                        <p class="info-nm">소분류</p>
                                        <p class="item-value item-mclas">
                                            {data.sclas_NM == null
                                                ? ""
                                                : data.sclas_NM}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                {/each}
            </ul>
        </div>

        <div class="order-title">
            <p><span class="order-title-in">배송정보</span></p>
        </div>
        <div class="cmn-input" style="margin-top: 19px;">
            <p>담당자명</p>
            <input
                bind:value={name_value}
                type="text"
                id="name_m"
                maxlength="30"
                placeholder="담당자명을 입력하세요."
            />
        </div>
        <div class="cmn-input" style="margin-top: 19px;">
            <p>담당자 연락처</p>
            <input
                bind:value={phone_value}
                bind:this={phone_this}
                type="text"
                id="phone_m"
                placeholder="담당자명 연락처를 입력하세요. (-) 제외"
                maxlength="11"
            />
        </div>
        <div class="cmn-input" style="margin-top: 19px;">
            <p>주소</p>
            <div class="cmn-input-btn">
                <input
                    bind:value={addr1_value}
                    type="text"
                    name="addr1"
                    id="addr1"
                    readonly
                    on:click={kakaopost}
                />
                <button on:click={kakaopost}>변경</button>
            </div>
            <input
                bind:value={addr2_value}
                type="text"
                name="addr2"
                id="addr2"
                placeholder="상세주소를 입력하세요."
                maxlength="50"
            />
        </div>
        <div class="cmn-input" style="margin-top: 19px;">
            <p>유무상구분</p>
            <select
                class="pay-se"
                id="pay_se_m"
                bind:value={pay_se_value}
                on:change={(e) => {
                    pay_se_value = e.target.value;
                }}
            >
                <option value="Y">유상</option>
                <option value="N">무상</option>
            </select>
        </div>
        <div class="cmn-input" style="margin-top: 19px;">
            <p>거래처명</p>
            <input bind:value={bcnc_nm_value} type="text" id="bcnc_nm_m" />
        </div>
        <div class="order-title">
            <p><span class="order-title-in">문의</span></p>
        </div>
        <div class="cmn-textarea">
            <textarea
                bind:value={note_value}
                id="note_m"
                placeholder="문의내용을 입력하세요."
            ></textarea>
        </div>
        <div class="cart-amount">
            <p class="cart-amount-chk">확인해주세요!</p>
            <p class="cart-amount-sum">
                <span>총 주문 품목 :&nbsp;</span>
                <span class="cart-item-sum" id="total_cate_m"
                    >{total_cate}개</span
                >
                <span>&nbsp;&nbsp;&nbsp;&nbsp;합계 :&nbsp;</span>
                <span class="cart-item-sum" id="total_cnt_m">{total_cnt}개</span
                >
            </p>
        </div>
        <div class="cmn-btn">
            <button on:click={order}>주문</button>
        </div>
    </div>

    <div class="pc pc-inner">
        <WebSideMenu />

        <div
            class="pc-wh"
            style="width: calc(100% - 360px); margin-left: 370px; margin-top: 40px; margin-bottom: 20px;"
        >
            <div class="cmn-title" style="font-weight: 600; text-align: left;">
                <p>A/S 부품 주문서</p>
            </div>

            <div class="cart-list-wrap order-list-wrap">
                <div>
                    <ul
                        class="scroll-custom"
                        id="item-list"
                        style="margin-top: 60px; max-height: 552px; width: 718px;"
                    >
                        {#each cart_list as data}
                            <li class="cart-list order-list">
                                <div>
                                    <div class="item-dtl">
                                        <div class="item-img">
                                            <img
                                                src={data.photo_FILE == null ||
                                                data.photo_FILE == ""
                                                    ? "resource/img/img_item.png"
                                                    : Common.imgCnv(
                                                          data.photo_FILE
                                                      )}
                                                alt=""
                                            />
                                        </div>
                                        <div class="item-info cart-item-info">
                                            <p class="item-nm">
                                                {data.item_AS_NM}
                                            </p>
                                            <div class="item-info-wrap">
                                                <p class="info-nm">품목코드</p>
                                                <p class="item-value item-code">
                                                    {data.item_CODE}
                                                </p>
                                            </div>
                                            <div class="item-info-wrap">
                                                <p class="info-nm">규격</p>
                                                <p
                                                    class="item-value item-stndrd"
                                                >
                                                    {data.stndrd == null
                                                        ? ""
                                                        : data.stndrd}
                                                </p>
                                            </div>
                                            <div class="item-info-wrap">
                                                <p class="info-nm">중분류</p>
                                                <p
                                                    class="item-value item-mclas"
                                                >
                                                    {data.mlsfc_NM == null
                                                        ? ""
                                                        : data.mlsfc_NM}
                                                </p>
                                            </div>
                                            <div class="item-info-wrap">
                                                <p class="info-nm">소분류</p>
                                                <p
                                                    class="item-value item-mclas"
                                                >
                                                    {data.sclas_NM == null
                                                        ? ""
                                                        : data.sclas_NM}
                                                </p>
                                            </div>
                                            <div class="item-count-num">
                                                <p>수량&nbsp;</p>
                                                <span>{data.qntt}개</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        {/each}
                    </ul>
                    <div class="cart-sum" style="width: 712px;">
                        <p style="margin-right: 39px;">
                            <span>- 총 주문 품목 :&nbsp;</span>
                            <span class="cart-item-sum" id="total_cate"
                                >{total_cate}개</span
                            >
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;- 합계 :&nbsp;</span>
                            <span class="cart-item-sum" id="total_cnt"
                                >{total_cnt}개</span
                            >
                        </p>
                    </div>
                </div>

                <div class="delivery-info">
                    <p class="delivery-title" style="padding-top: 28px;">
                        배송정보
                    </p>
                    <div class="delivery-info-in">
                        <p class="delivery-info-title">담당자명</p>
                        <input
                            bind:value={name_value}
                            type="text"
                            id="name"
                            placeholder="담당자명을 입력하세요."
                            maxlength="30"
                        />
                    </div>
                    <div class="delivery-info-in">
                        <p class="delivery-info-title">담당자 연락처</p>
                        <input
                            bind:value={phone_value}
                            type="text"
                            id="phone"
                            placeholder="담당자명 연락처를 입력하세요. (-) 제외"
                            maxlength="11"
                        />
                    </div>
                    <div class="delivery-info-in">
                        <p class="delivery-info-title">주소</p>
                        <input
                            bind:value={addr1_value}
                            type="text"
                            name="addr1_pc"
                            id="addr1_pc"
                            readonly
                            on:click={kakaopost}
                            style="width: 180px; cursor: pointer;"
                        />
                        <button on:click={kakaopost} class="addr-btn"
                            >변경</button
                        >
                    </div>
                    <div class="delivery-info-in">
                        <p class="delivery-info-title"></p>
                        <input
                            bind:value={addr2_value}
                            type="text"
                            id="addr2_pc"
                            placeholder="상세주소를 입력하세요."
                            maxlength="50"
                        />
                    </div>
                    <div class="delivery-info-in">
                        <p class="delivery-info-title">유무상구분</p>
                        <div class="pay-se">
                            <input
                                on:change={() => {
                                    pay_se_value = "Y";
                                }}
                                type="radio"
                                name="pay_se"
                                value="Y"
                                checked={pay_se_value === "Y" ? true : false}
                            /><span>유상</span>
                            <input
                                on:change={() => {
                                    pay_se_value = "N";
                                }}
                                type="radio"
                                name="pay_se"
                                value="N"
                                checked={pay_se_value === "N" ? true : false}
                            /><span>무상</span>
                        </div>
                    </div>

                    <div class="delivery-info-in">
                        <p class="delivery-info-title">거래처명</p>
                        <input
                            bind:value={bcnc_nm_value}
                            type="text"
                            id="bcnc_nm"
                            placeholder="거래처명을 입력하세요."
                        />
                    </div>

                    <p class="delivery-title">문의</p>
                    <div
                        class="cmn-textarea"
                        style="width: 392px; margin: 0 auto;"
                    >
                        <textarea
                            bind:value={note_value}
                            id="note"
                            placeholder="문의내용을 입력하세요."
                            style="height: 100px;"
                        ></textarea>
                    </div>
                    <div
                        class="cmn-btn"
                        style="width: 284px; margin: 0 auto; margin-top: 36px;"
                    >
                        <button
                            style="background: var(--main-color, #E54031);"
                            on:click={order}>주문등록</button
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
