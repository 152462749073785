<script>
    import { onMount } from "svelte";
    import * as Common from "../utiles/common.util";
    import { apiPostlogin } from "../store/auth.store";
    import LoginHeader from "../components/LoginHeader.svelte";
    import { push } from "svelte-spa-router";

    let biz_no = "";
    let pw = "";

    let biz_no_m = "";
    let pw_m = "";

    onMount(() => {
        if (!window.location.href.includes("/login")) {
            push("/login");
        }

        Common.deleteCookie("BIZ_NO");
        Common.deleteCookie("PW");
        Common.deleteCookie("EMAIL");
    });

    async function login_m() {
        if (Common.nullCheck(biz_no_m, "biz_no_m", "사업자등록번호")) return;
        if (Common.nullCheck(pw_m, "pw_m", "비밀번호")) return;

        await apiPostlogin(biz_no_m, pw_m);
    }

    async function login() {
        if (Common.nullCheck(biz_no, "biz_no", "사업자등록번호")) return;
        if (Common.nullCheck(pw, "pw", "비밀번호")) return;

        await apiPostlogin(biz_no, pw);
    }

    function formatInput_m(event) {
        biz_no_m = event.target.value.replace(/-/g, "");
    }

    function formatInput(event) {
        biz_no = event.target.value.replace(/-/g, "");
    }
</script>

<LoginHeader />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<section class="main inner">
    <div class="mobile">
        <div class="login-img">
            <img src="resource/img/img_login.png" alt="" class="m-login" />
        </div>
        <div class="cmn-title">
            <p>Login</p>
        </div>
        <div class="cmn-input">
            <p>사업자등록번호</p>
            <input
                type="text"
                bind:value={biz_no_m}
                on:input={formatInput_m}
                on:keypress={(e) => {
                    e.key === "Enter" ? login_m() : "";
                }}
                placeholder="사업자등록번호를 입력해주세요.(-제외)"
            />
        </div>
        <div class="cmn-input" style="margin-top: 20px;">
            <p>비밀번호</p>
            <input
                type="password"
                bind:value={pw_m}
                on:keypress={(e) => {
                    e.key === "Enter" ? login_m() : "";
                }}
                placeholder="비밀번호를 입력해주세요."
                maxlength="20"
            />
        </div>
        <div class="login-pw-find">
            <span class="cmn-gray">비밀번호를 잊어버리셨나요?</span>
            <span
                class="cmn-navy"
                on:click={() => {
                    push("/as_password/pw_find");
                }}>클릭</span
            >
        </div>
        <div class="cmn-btn">
            <button on:click={login_m}>Login</button>
        </div>
        <div class="login-join">
            <span
                class="cmn-navy"
                on:click={() => {
                    push("/as_join/join_id");
                }}>회원가입 하시겠어요?</span
            >
        </div>
    </div>

    <div class="pc pc-inner main-pad">
        <div class="pc-main">
            <div class="login-img">
                <img src="resource/img/img_login.png" alt="" class="m-login" />
            </div>
            <div class="cmn-title">
                <p>Login</p>
            </div>
            <div class="login-sign-in">
                <img src="resource/img/img_line_login.png" alt="" />
                <p>Sign In</p>
            </div>
            <div class="cmn-input" style="margin-top: 32px;">
                <p>사업자등록번호</p>
                <input
                    type="text"
                    id="biz_no"
                    bind:value={biz_no}
                    on:input={formatInput}
                    on:keypress={(e) => {
                        e.key === "Enter" ? login() : "";
                    }}
                    placeholder="사업자등록번호를 입력해주세요.(-제외)"
                />
            </div>
            <div class="cmn-input" style="margin-top: 20px;">
                <p>비밀번호</p>
                <input
                    type="password"
                    id="pw"
                    bind:value={pw}
                    on:keypress={(e) => {
                        e.key === "Enter" ? login() : "";
                    }}
                    placeholder="비밀번호를 입력해주세요."
                    maxlength="20"
                />
            </div>
            <div class="login-pw-find">
                <span class="cmn-gray">비밀번호를 잊어버리셨나요?&nbsp;</span>
                <span
                    class="cmn-navy"
                    style="cursor: pointer;"
                    on:click={() => {
                        push("/as_password/pw_find")
                    }}>클릭</span
                >
            </div>
            <div class="cmn-btn" style="width: 424px; margin: 0 auto;">
                <button on:click={login}>Login</button>
            </div>
            <div
                class="cmn-btn cmn-btn-blue"
                style="width: 424px; margin: 0 auto; margin-top: 10px;"
            >
                <button
                    on:click={() => {
                        push("/as_join/join_id")
                    }}>Sign up</button
                >
            </div>
        </div>
    </div>
</section>
