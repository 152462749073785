<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import * as api from "../../store/asQuestion.store";
    import Header from "../../components/Header.svelte";
    import WebSideMenu from "../../components/WebSideMenu.svelte";
    import { push } from "svelte-spa-router";

    let INQUIRY_ID = "";

    let title = "";
    let content = "";
    let reg_date = "";
    let path_img = "";
    let is_status = "";
    let answer = "";
    let answer_date = "";
    let is_status_input = "";

    onMount(() => {
        const param = Common.getURLParameters();

        if (param.id != undefined) {
            INQUIRY_ID = param.id;
            getPageData();
        } else {
            alert("잘못된 접근입니다.");
            push("/login");
        }
    });

    // -------------------------------- PC --------------------------------
    // 조회
    async function getPageData() {
        const response = await api.apiGetAsInquirySelect(INQUIRY_ID);

        title = response.title;
        content = response.content.replaceAll("<br>", "\r\n");
        reg_date = response.reg_DATE.replace(/\.\d+$/, "").replaceAll("-", ".");
        path_img = Common.imgCnv(response.path_IMG);

        is_status =
            response.is_STATUS === "1"
                ? "미답변"
                : response.is_STATUS === "2"
                  ? "답변완료"
                  : "";

        if (response.is_STATUS === "1") {
            document.querySelectorAll(".is_STATUS").forEach((element) => {
                element.style.color = "black";
            });

            is_status_input = is_status;
        } else {
            answer = response.answer.replaceAll("<br>", "\r\n");
            answer_date = response.answer_DATE
                .replace(/\.\d+$/, "")
                .replaceAll("-", ".");
            is_status_input = `${is_status} (${answer_date})`;
        }
    }

    // 삭제하기
    async function qna_del() {
        if (await api.apiPostAsInquiryDelete(INQUIRY_ID)) {
            push("/as_question/qna_list");
        }
    }
</script>

<Header />

<section class="main inner main-pad-36" style="position: relative;">
    <div class="mobile">
        <div
            style="border-bottom: 1px solid var(--line, #ECECEC); position: relative;"
        >
            <div
                class="cmn-title-left"
                style="padding-top: 20px; padding-bottom: 15px;"
            >
                <p>문의하기</p>
            </div>
            <div style="padding-bottom: 10px;">
                <div class="cart-order-list gray-width" style="padding: 0;">
                    <ul>
                        <li style="border-bottom: none;">
                            <div class="list-in">
                                <p class="list-title">작성일자</p>
                                <p class="reg_DATE">{reg_date}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div
            class="qna-btn-gray qna-btn-red"
            style="margin-top: 11px; position: absolute; right: 0;"
        >
            <button class="is_STATUS_m">{is_status}</button>
        </div>
        <div
            class="cmn-input"
            style="margin-top: 57px; padding-bottom: 11px; border-bottom: 1px solid var(--line, #ECECEC); border-top: 1px solid var(--line, #ECECEC);"
        >
            <p id="title_m">{title}</p>
        </div>
        <div
            class="cmn-textarea"
            style="margin-top: 50px; border-bottom: 1px solid var(--line, #ECECEC);"
        >
            <textarea style="border: none;" id="content_m" readonly
                >{content}</textarea
            >
        </div>
        <div class="qna-img-wrap">
            <img src={path_img} alt="" class="qna-img" id="path_IMG_m" />
        </div>
        <div class="cmn-btn cmn-btn-gray" style="margin-top: 52px;">
            <button on:click={qna_del}>삭제하기</button>
        </div>
    </div>

    <div class="pc pc-inner">
        <WebSideMenu />

        <div
            class="pc-wh"
            style="width: calc(100% - 360px); margin-left: 370px; margin-top: 40px; margin-bottom: 20px;"
        >
            <div
                class="cmn-title"
                style="font-weight: 600; text-align: left; margin-bottom: 30px; "
            >
                <p>문의하기</p>
            </div>

            <div
                class="scroll-custom"
                style=" width: 1216px; height: 750px; overflow: scroll;"
            >
                <div class="qna-write" style="margin-top: 0px;">
                    <div class="qna-write-wrap">
                        <input
                            value={title}
                            type="text"
                            class="write-title-in"
                            id="title"
                            readonly
                            style="border: none; box-shadow: none;"
                        />
                        <div
                            style="display: flex; position: absolute; right: 20px;"
                        >
                            <p class="reg_DATE">
                                {reg_date
                                    .replace(/\.\d+$/, "")
                                    .replaceAll("-", ".")}
                            </p>
                            <p
                                class="is_STATUS"
                                style="padding-left: 30px; color: #E54031; font-weight: 600;"
                            >
                                {is_status}
                            </p>
                        </div>
                    </div>
                    <div class="qna-contents">
                        <textarea
                            class="scroll-custom"
                            placeholder="문의내용을 입력해주세요."
                            id="content"
                            readonly>{content}</textarea
                        >
                    </div>
                </div>

                <div
                    class="qna-write scroll-custom"
                    style="margin-top: 10px; overflow-y: scroll;"
                >
                    <div class="qna-img">
                        <img
                            src={path_img}
                            alt=""
                            id="path_IMG"
                            style="max-width: 800px; height: calc( 100% - 20px );"
                        />
                    </div>
                </div>

                <div class="qna-write" style="margin-top: 10px;">
                    <div class="qna-write-wrap">
                        <input
                            value={is_status_input}
                            type="text"
                            class="write-title-in is_STATUS_input"
                            readonly
                            style="border: none; box-shadow: none;"
                        />
                    </div>
                    <div class="qna-contents">
                        <textarea class="scroll-custom answer" readonly
                            >{answer}</textarea
                        >
                    </div>
                </div>

                <div
                    class="cmn-btn"
                    style="position: relative; width: 1201px; display: flex;"
                >
                    <div style="position: relative; width: 100%;">
                        <button
                            style="width: 284px; margin: 0 auto; background: var(--line, #ECECEC); color: var(--space-font-color, #AFAFAF);"
                            on:click={qna_del}>삭제하기</button
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
