<script>
    import { onMount } from "svelte";
    import { apiGetStatus, apiGetLogout } from "../store/auth.store";
    import { push } from "svelte-spa-router";

    onMount(() => {
        // 로그인 상태 체크
        apiGetStatus();
    });

    async function logout() {
        await apiGetLogout();
    }
</script>

<link rel="stylesheet" href="resource/css/common.css" />
<link rel="stylesheet" href="resource/css/style.css" />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<header class="header">
    <div class="mobile hd-title inner">
        <div class="hd-title-wrap">
            <img
                src="resource/img/ico_arrow_left.png"
                alt=""
                class="m-arrow-l"
                on:click={() => {
                    history.back();
                }}
            />
            <img
                src="resource/img/img_logo.png"
                alt=""
                class="m-logo m-logo-center"
                on:click={() => {
                    push("/as_home/home");
                }}
            />
            <img
                src="resource/img/ico_home.png"
                alt=""
                class="m-home"
                on:click={() => {
                    push("/as_home/home");
                }}
            />
            <img
                src="resource/img/ico_cart.png"
                alt=""
                class="m-cart"
                on:click={() => {
                    push("/as_cart/cart_list");
                }}
            />
        </div>
    </div>
    <div class="pc hd-inner">
        <img
            src="resource/img/img_logo.png"
            on:click={() => {
                push("/as_home/home");
            }}
            alt=""
        />
        <p
            on:click={() => {
                push("/as_cart/cart_list");
            }}
            style="margin-right: 100px; padding-right: 20px; border-right: 1px solid #67737C;"
        >
            장바구니
        </p>
        <p on:click={logout}>로그아웃</p>
    </div>
</header>
