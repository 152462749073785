import ItemList from "../view/asItem/ItemList.svelte";
import ItemSearch from "../view/asItem/ItemSearch.svelte";
import ItemDtl from "../view/asItem/ItemDtl.svelte";

export const asItemRoutes = {
    "/as_item/item_list": ItemList,
    "/as_item/item_search": ItemSearch,
    "/as_item/item_dtl": ItemDtl,
    // "/search/": Search,
    // "/todo/:id": ToDo,
};
