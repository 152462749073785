import { writable } from "svelte/store";
import * as api from "../apis/join.api";
import { push } from "svelte-spa-router";

export const scannerMsg = writable("");

export async function apiFindByBizNo(BIZ_NO) {
    try {
        const response = await api.apiFindByBizNo(BIZ_NO);

        if (response.status === 200) {
            if (response.data.code === 200) {
                return true;
            } else {
                alert(response.data.msg);
                return false;
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiFindByEmail(EMAIL) {
    try {
        const response = await api.apiFindByEmail(EMAIL);

        if (response.status === 200) {
            if (response.data.code === 200) {
                return true;
            } else {
                alert(response.data.msg);
                return false;
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiEmailAuth(emailAuth) {
    try {
        const response = await api.apiEmailAuth(emailAuth);

        if (response.status === 200) {
            alert(response.data.msg);

            if (response.data.code === 200) {
                return true;
            } else {
                return false;
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiPostJoin(
    BIZ_NO,
    PW,
    EMAIL,
    FILE_BSNS_LCNS,
    FILE_COPY_ACNT,
    FILE_BSNS_LCNS_EXTENSION,
    FILE_COPY_ACNT_EXTENSION,
    BSN_CHARGER,
    BPLC_ZIP_CODE,
    BPLC_ADRES_1,
    BPLC_ADRES_2,
    BSN_CHARGER_TELNO
) {
    try {
        const formData = {
            BIZ_NO: BIZ_NO,
            PW: PW,
            EMAIL: EMAIL,
            FILE_BSNS_LCNS: FILE_BSNS_LCNS,
            FILE_COPY_ACNT: FILE_COPY_ACNT,
            FILE_BSNS_LCNS_EXTENSION: FILE_BSNS_LCNS_EXTENSION,
            FILE_COPY_ACNT_EXTENSION: FILE_COPY_ACNT_EXTENSION,
            BSN_CHARGER: BSN_CHARGER,
            BPLC_ZIP_CODE: BPLC_ZIP_CODE,
            BPLC_ADRES_1: BPLC_ADRES_1,
            BPLC_ADRES_2: BPLC_ADRES_2,
            BSN_CHARGER_TELNO: BSN_CHARGER_TELNO,
        };

        const response = await api.apiPostJoin(formData);

        if (response.status === 200) {
            alert(response.data.msg);

            if (response.data.code === 200) {
                return true;
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
            return false;
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiGetLogout() {
    try {
        const response = await authApi.apiGetLogout();

        if (response.status === 200) {
            if (response.data.code === 200) {
                push("/login");
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiPostJoinFindByPw(PW) {
    try {
        const formData = {
            PW: PW,
        };

        const response = await api.apiPostJoinFindByPw(formData);

        if (response.status === 200) {
            if (response.data.code === 200) {
                return true;
            } else {
                alert(response.data.msg);
                return false;
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiPostJoinModifyPw(PW) {
    try {
        const formData = {
            PW: PW,
        };

        const response = await api.apiPostJoinModifyPw(formData);

        if (response.status === 200) {
            if (response.data.code === 200) {
                return true;
            } else {
                alert(response.data.msg);
                return false;
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}
