<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import * as api from "../../store/asCart.store";
    import Header from "../../components/Header.svelte";
    import WebSideMenu from "../../components/WebSideMenu.svelte";
    import { push } from "svelte-spa-router";

    let arr = [];
    let arr_m = [];

    let cart_list = [];
    let total_cate = 0;
    let total_cnt = 0;

    onMount(() => {
        getPageData();
    });

    // ------------------------------ PC ------------------------------
    // 검색
    async function getPageData() {
        cart_list = await api.apiGetAsCartFindAll();

        total_cate = cart_list.length;
        total_cnt = 0;

        for (let i = 0; i < cart_list.length; i++) {
            total_cnt += cart_list[i].qntt;
        }
    }

    // 장바구니 - 수량 변경
    async function item_cart_update(cart_id, currentValue) {
        const CART_ID = cart_id;
        const QNTT = currentValue;

        if (await api.apiPostAsCartUpdate(CART_ID, QNTT)) {
            getPageData();
        }
    }
    // 장바구니 - 품목 수량 빼기
    function item_cart_m_pc(cart_id) {
        const currentValue = parseInt(
            document.getElementById(`qntt${cart_id}`).innerText
        );

        if (currentValue == 1) {
            alert("1개 이상 주문 가능합니다.");
        } else {
            item_cart_update(cart_id, currentValue - 1);
        }
    }

    // 장바구니 - 품목 수량 더하기
    function item_cart_p_pc(cart_id) {
        const currentValue = parseInt(
            document.getElementById(`qntt${cart_id}`).innerText
        );

        item_cart_update(cart_id, currentValue + 1);
    }

    // 삭제
    async function delete_chk(CART_ID) {
        if (await api.apiPostAsCartDelete(CART_ID)) {
            location.reload();
        }
    }

    // 선택삭제 -1 (배열넣기)
    function arr_push_pc() {
        arr = [];

        var checkboxes = document.querySelectorAll(
            "input[type='checkbox'][name='checkpc']:checked"
        );

        checkboxes.forEach(function (checkbox) {
            arr.push(checkbox.value);
        });
    }

    // 선택삭제 -2 (배열지우기)
    function delete_arr() {
        if (arr.length > 0) {
            let strs = [];

            for (var i = 0; i < arr.length; i++) {
                var num = arr[i].split("cart-item-pc-")[1];
                strs.push(num);
            }

            delete_chk(strs);
        } else {
            alert("선택한 부품이 없습니다.");
        }
    }

    // 주문
    function order() {
        if (arr.length > 0) {
            let strs = [];

            for (var i = 0; i < arr.length; i++) {
                var num = arr[i].split("cart-item-pc-")[1];
                strs.push(num);
            }

            Common.setCookie("orderList", strs, 1);
            push("/as_cart/cart_order")
        } else {
            alert("선택한 부품이 없습니다.");
        }
    }

    // 체크박스
    // 1) 개별 선택 체크박스 클릭 -pc
    function checkSelectAll_pc() {
        var checkboxes = document.querySelectorAll('input[name="checkpc"]'); // 전체 체크박스
        var checked = document.querySelectorAll(
            'input[name="checkpc"]:checked'
        ); // 선택된 체크박스
        var selectAll = document.querySelector('input[name="selectallpc"]'); // select all 체크박스

        if (checkboxes.length === checked.length) {
            selectAll.checked = true;
        } else {
            selectAll.checked = false;
        }
    }

    // 2) 전체 선택 체크박스 클릭 -pc
    function selectAll_pc(selectAll) {
        var checkboxes = document.getElementsByName("checkpc");

        checkboxes.forEach((checkbox) => {
            checkbox.checked = selectAll.target.checked;
        });

        arr_push_pc();
    }

    // ------------------------------ Mobile ------------------------------

    // 장바구니 - 품목 수량 빼기
    function item_cart_m(cart_id) {
        const currentValue = parseInt(
            document.getElementById(`m_qntt${cart_id}`).innerText
        );

        if (currentValue == 1) {
            alert("1개 이상 주문 가능합니다.");
        } else {
            item_cart_update(cart_id, currentValue - 1);
        }
    }

    // 장바구니 - 품목 수량 더하기
    function item_cart_p(cart_id) {
        const currentValue = parseInt(
            document.getElementById(`m_qntt${cart_id}`).innerText
        );

        item_cart_update(cart_id, currentValue + 1);
    }

    // 선택삭제 -1 (배열넣기)
    function arr_push_m() {
        arr_m = [];

        var checkboxes = document.querySelectorAll(
            "input[type='checkbox'][name='checkm']:checked"
        );

        checkboxes.forEach(function (checkbox) {
            arr_m.push(checkbox.value);
        });
    }

    // 선택삭제 -2 (배열지우기)
    function delete_arr_m() {
        if (arr_m.length > 0) {
            let strs = [];

            for (var i = 0; i < arr_m.length; i++) {
                var num = arr_m[i].split("cart-item-")[1];
                strs.push(num);
            }

            delete_chk(strs);
        } else {
            alert("선택한 부품이 없습니다.");
        }
    }

    // 체크박스
    // 1) 개별 선택 체크박스 클릭 -mobile
    function checkSelectAll() {
        var checkboxes = document.querySelectorAll('input[name="checkm"]'); // 전체 체크박스
        var checked = document.querySelectorAll('input[name="checkm"]:checked'); // 선택된 체크박스
        var selectAll = document.querySelector('input[name="selectall"]'); // select all 체크박스

        if (checkboxes.length === checked.length) {
            selectAll.checked = true;
        } else {
            selectAll.checked = false;
        }
    }
    // 2) 전체 선택 체크박스 클릭 -mobile
    function selectAll(selectAll) {
        var checkboxes = document.getElementsByName("checkm");

        checkboxes.forEach((checkbox) => {
            checkbox.checked = selectAll.target.checked;
        });

        arr_push_m();
    }

    // 주문
    function order_m() {
        if (arr_m.length > 0) {
            let strs = [];

            for (var i = 0; i < arr_m.length; i++) {
                var num = arr_m[i].split("cart-item-")[1];
                strs.push(num);
            }

            Common.setCookie("orderList", strs, 1);
            push("/as_cart/cart_order")
        } else {
            alert("선택한 부품이 없습니다.");
        }
    }
</script>

<Header />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<section class="main inner main-pad" style="position: relative;">
    <div class="mobile">
        <div class="cmn-title-left" style="margin-top: 20px;">
            <p>A/S 부품 장바구니</p>
        </div>
        <div class="cart-chk" style="margin-top: 38px;">
            <input
                type="checkbox"
                id="all"
                name="selectall"
                on:change={selectAll}
            />
            <label for="all" id="all-chk" class="all-checkbox"></label>
            <span style="margin-left: 5px;">전체선택</span>
            <span style="margin-left: 25px;" on:click={delete_arr_m}
                >선택삭제</span
            >
        </div>
        <div class="item-list">
            <ul style="padding-bottom: 0;" id="item-list_m">
                {#if cart_list.length > 0}
                    {#each cart_list as data}
                        <li class="cart-list cart-inner-m" id="cart-inner-m-1">
                            <div class="cart-chk" style="margin-right: 12px;">
                                <input
                                    type="checkbox"
                                    id="cart-item-{data.cart_ID}"
                                    name="checkm"
                                    value="cart-item-{data.cart_ID}"
                                    on:click={arr_push_m}
                                    on:change={checkSelectAll}
                                />
                                <label for="cart-item-{data.cart_ID}"></label>
                            </div>
                            <div>
                                <p class="item-nm">{data.item_AS_NM}</p>
                                <img
                                    src="resource/img/ico_delete.png"
                                    alt=""
                                    class="cart-delete"
                                    on:click={() => {
                                        delete_chk(data.cart_ID);
                                    }}
                                />
                                <div class="item-dtl">
                                    <div class="item-img">
                                        <img
                                            src={data.photo_FILE == null ||
                                            data.photo_FILE == ""
                                                ? "resource/img/img_item.png"
                                                : Common.imgCnv(
                                                      data.photo_FILE
                                                  )}
                                            alt=""
                                        />
                                    </div>
                                    <div class="item-info cart-item-info">
                                        <div class="item-info-wrap">
                                            <p class="info-nm">품목코드</p>
                                            <p class="item-value item-code">
                                                {data.item_CODE}
                                            </p>
                                        </div>
                                        <div class="item-info-wrap">
                                            <p class="info-nm">규격</p>
                                            <p class="item-value item-stndrd">
                                                {data.stndrd == null
                                                    ? ""
                                                    : data.stndrd}
                                            </p>
                                        </div>
                                        <div class="item-info-wrap">
                                            <p class="info-nm">중분류</p>
                                            <p class="item-value item-mclas">
                                                {data.mlsfc_NM == null
                                                    ? ""
                                                    : data.mlsfc_NM}
                                            </p>
                                        </div>
                                        <div class="item-info-wrap">
                                            <p class="info-nm">소분류</p>
                                            <p class="item-value item-mclas">
                                                {data.sclas_NM == null
                                                    ? ""
                                                    : data.sclas_NM}
                                            </p>
                                        </div>
                                        <div class="item-count">
                                            <p
                                                class="count"
                                                style="position: absolute; left: 0;"
                                                on:click={() => {
                                                    item_cart_m(data.cart_ID);
                                                }}
                                            >
                                                -
                                            </p>
                                            <p id="m_qntt{data.cart_ID}">
                                                {data.qntt}
                                            </p>
                                            <p
                                                class="count"
                                                style="position: absolute; right: 0;"
                                                on:click={() => {
                                                    item_cart_p(data.cart_ID);
                                                }}
                                            >
                                                +
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    {/each}
                {:else}
                    <p>검색 결과가 없습니다.</p>
                {/if}
            </ul>
        </div>
        <div class="cart-sum">
            <p>
                <span>- 총 주문 품목 :&nbsp;</span>
                <span class="cart-item-sum" id="total_cate_m"
                    >{total_cate}개</span
                >
                <span>&nbsp;&nbsp;&nbsp;&nbsp;- 합계 :&nbsp;</span>
                <span class="cart-item-sum" id="total_cnt_m">{total_cnt}개</span
                >
            </p>
        </div>
        <div class="cmn-btn cmn-btn-btm">
            <button on:click={order_m}>주문</button>
        </div>
    </div>

    <div class="pc pc-inner">
        <WebSideMenu />

        <div
            class="pc-wh"
            style="width: calc(100% - 360px); margin-left: 370px; margin-top: 40px; margin-bottom: 20px;"
        >
            <div class="cmn-title" style="font-weight: 600; text-align: left;">
                <p>A/S 부품 장바구니</p>
            </div>

            <div class="cart-chk" style="margin-top: 38px;">
                <input
                    type="checkbox"
                    id="all-pc"
                    name="selectallpc"
                    on:change={selectAll_pc}
                />
                <label
                    for="all-pc"
                    id="all-chk"
                    class="all-checkbox"
                    style="margin-bottom: 6px;"
                ></label>
                <span style="margin-left: 5px;">전체선택</span>
                <span
                    style="margin-left: 13px; cursor: pointer;"
                    on:click={delete_arr}>선택삭제</span
                >
            </div>

            <div class="cart-list-wrap">
                <ul class="scroll-custom" id="item-list">
                    {#if cart_list.length > 0}
                        {#each cart_list as data}
                            <li class="cart-list cart-inner-pc">
                                <div
                                    class="cart-chk"
                                    style="margin-right: 12px; margin-top: 30px; margin-left: 16px;"
                                >
                                    <input
                                        type="checkbox"
                                        id="cart-item-pc-{data.cart_ID}"
                                        name="checkpc"
                                        value="cart-item-pc-{data.cart_ID}"
                                        on:click={arr_push_pc}
                                        on:change={checkSelectAll_pc}
                                    />
                                    <label for="cart-item-pc-{data.cart_ID}"
                                    ></label>
                                </div>
                                <div>
                                    <img
                                        src="resource/img/ico_delete.png"
                                        alt=""
                                        class="cart-delete"
                                        on:click={() => {
                                            delete_chk(data.cart_ID);
                                        }}
                                    />
                                    <div class="item-dtl">
                                        <div class="item-img">
                                            <img
                                                src={data.photo_FILE == null ||
                                                data.photo_FILE == ""
                                                    ? "resource/img/img_item.png"
                                                    : Common.imgCnv(
                                                          data.photo_FILE
                                                      )}
                                                alt=""
                                            />
                                        </div>
                                        <div class="item-info cart-item-info">
                                            <p class="item-nm">
                                                {data.item_AS_NM}
                                            </p>
                                            <div class="item-info-wrap">
                                                <p class="info-nm">품목코드</p>
                                                <p class="item-value item-code">
                                                    {data.item_CODE}
                                                </p>
                                            </div>
                                            <div class="item-info-wrap">
                                                <p class="info-nm">규격</p>
                                                <p
                                                    class="item-value item-stndrd"
                                                >
                                                    {data.stndrd == null
                                                        ? ""
                                                        : data.stndrd}
                                                </p>
                                            </div>
                                            <div class="item-info-wrap">
                                                <p class="info-nm">중분류</p>
                                                <p
                                                    class="item-value item-mclas"
                                                >
                                                    {data.mlsfc_NM == null
                                                        ? ""
                                                        : data.mlsfc_NM}
                                                </p>
                                            </div>
                                            <div class="item-info-wrap">
                                                <p class="info-nm">소분류</p>
                                                <p
                                                    class="item-value item-mclas"
                                                >
                                                    {data.sclas_NM == null
                                                        ? ""
                                                        : data.sclas_NM}
                                                </p>
                                            </div>
                                            <div class="item-count">
                                                <p
                                                    class="count"
                                                    style="position: absolute; left: 0;"
                                                    on:click={() => {
                                                        item_cart_m_pc(
                                                            data.cart_ID
                                                        );
                                                    }}
                                                >
                                                    -
                                                </p>
                                                <p
                                                    class="item_qy"
                                                    id="qntt{data.cart_ID}"
                                                >
                                                    {data.qntt}
                                                </p>
                                                <p
                                                    class="count"
                                                    style="position: absolute; right: 0;"
                                                    on:click={() => {
                                                        item_cart_p_pc(
                                                            data.cart_ID
                                                        );
                                                    }}
                                                >
                                                    +
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        {/each}
                    {:else}
                        <p>검색 결과가 없습니다.</p>
                    {/if}
                </ul>
            </div>

            <div class="cart-sum">
                <p>
                    <span>- 총 주문 품목 :&nbsp;</span>
                    <span class="cart-item-sum" id="total_cate"
                        >{total_cate}개</span
                    >
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;- 합계 :&nbsp;</span>
                    <span class="cart-item-sum" id="total_cnt"
                        >{total_cnt}개</span
                    >
                </p>
            </div>
            <div class="cmn-btn buy-btn">
                <button on:click={order}>주문</button>
            </div>
        </div>
    </div>
</section>
