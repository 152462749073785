<script>
    import { onMount } from "svelte";
    import { apiGetStatus } from "../store/auth.store";

    onMount(() => {
        // 로그인 상태 체크
        apiGetStatus();
    });
</script>

<link rel="stylesheet" href="resource/css/common.css" />
<link rel="stylesheet" href="resource/css/style.css" />

<header class="header">
    <div class="mobile hd-title inner">
        <div class="hd-title-wrap">
            <img
                src="resource/img/img_logo.png"
                alt=""
                class="m-logo m-logo-center"
            />
        </div>
    </div>
    <div class="pc hd-inner">
        <img src="resource/img/img_logo.png" alt=""/>
    </div>
</header>
