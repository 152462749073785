import axios from "../utiles/axios.util";

function apiGetAsItemInit() {
    return axios.get(`/api/v1/asItem/init?LEVEL=1`);
}

function apiGetAsItemInitMlsfcCode(LCLAS_CODE) {
    return axios.get(`/api/v1/asItem/init?LCLAS_CODE=${LCLAS_CODE}`);
}

function apiGetAsItemInitSclasCode(MLSFC_CODE) {
    return axios.get(`/api/v1/asItem/init?MLSFC_CODE=${MLSFC_CODE}`);
}

function apiGetAsItemFindAll(
    page,
    ITEM_NM,
    STNDRD,
    LCLAS_CODE,
    MLSFC_CODE,
    SCLAS_CODE
) {
    return axios.get(
        `/api/v1/asItem/findAll?page=${page}&ITEM_NM=${ITEM_NM}&STNDRD=${STNDRD}&LCLAS_CODE=${LCLAS_CODE}&MLSFC_CODE=${MLSFC_CODE}&SCLAS_CODE=${SCLAS_CODE}`
    );
}

function apiPostAsItemCart(formData) {
    return axios.post("/api/v1/asItem/cart", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export {
    apiGetAsItemInit,
    apiGetAsItemInitMlsfcCode,
    apiGetAsItemInitSclasCode,
    apiGetAsItemFindAll,
    apiPostAsItemCart,
};
