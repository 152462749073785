<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import * as api from "../../store/asItem.store";
    import Header from "../../components/Header.svelte";
    import WebSideMenu from "../../components/WebSideMenu.svelte";
    import { each } from "svelte/internal";
    import { push } from "svelte-spa-router";

    let item_nm_value = "";
    let stndrd_value = "";

    let item_lclas_wrap_list = [];
    let item_lclas_wrap_this;

    let item_mlsfc_wrap_list = [];
    let item_mlsfc_wrap_this;
    let item_mlsfc_wrap_yn = true;

    let item_sclas_wrap_list = [];
    let item_sclas_wrap_this;
    let item_sclas_wrap_yn = true;

    onMount(() => {
        document.body.style.backgroundColor = "#F3F3F3";

        inin();
    });

    // 초기화
    async function inin() {
        item_lclas_wrap_list = await api.apiGetAsItemInit();
    }

    // 대분류 선택 시 중분류 데이터 변경
    async function selectLclas() {
        // 대분류 전체 선택 시
        if (item_lclas_wrap_this.value == "") {
            item_mlsfc_wrap_yn = true;
            item_sclas_wrap_yn = true;
            return;
        }

        item_mlsfc_wrap_list = await api.apiGetAsItemInitMlsfcCode(
            item_lclas_wrap_this.value
        );

        if (item_mlsfc_wrap_list.length > 0) {
            item_mlsfc_wrap_yn = false;
        }
    }

    // 중분류 선택 시 소분류 데이터 변경
    async function selectMlsfc() {
        // 중분류 전체 선택 시
        if (item_mlsfc_wrap_this.value == "") {
            item_sclas_wrap_yn = true;
            return;
        }

        item_sclas_wrap_list = await api.apiGetAsItemInitSclasCode(
            item_mlsfc_wrap_this.value
        );

        if (item_sclas_wrap_list.length > 0) {
            item_sclas_wrap_yn = false;
        }
    }
</script>

<Header />

<section class="main inner" style="position: relative;">
    <div class="cmn-title-left" style="margin-top: 20px;">
        <p>A/S 부품 조회</p>
    </div>
    <div class="cmn-input" style="margin-top: 15px;">
        <input
            bind:value={item_nm_value}
            type="text"
            id="item_nm"
            placeholder="A/S 품목명을 입력해 주세요."
        />
        <input
            bind:value={stndrd_value}
            type="text"
            id="stndrd"
            placeholder="규격을 입력해 주세요."
        />
    </div>
    <select
        bind:this={item_lclas_wrap_this}
        class="item-mclas-wrap"
        id="item-sclas-wrap"
        on:change={selectLclas}
        autocomplete="off"
        style="width: calc(100%)"
    >
        <option value="">대분류 전체</option>
        {#each item_lclas_wrap_list as data}
            <option value={data.lclas_CODE}>{data.cl_NM}</option>
        {/each}
    </select>

    <select
        bind:this={item_mlsfc_wrap_this}
        class="item-mclas-wrap"
        id="item-mclas-wrap"
        on:change={selectMlsfc}
        style="width: calc(100%)"
        autocomplete="off"
    >
        {#if item_mlsfc_wrap_yn}
            <option value="">중분류 전체</option>
        {:else}
            {#each item_mlsfc_wrap_list as data}
                {#if data.mlsfc_CODE != "?"}
                    <option value={data.mlsfc_CODE}>{data.cl_NM}</option>
                {:else}
                    <option value="">중분류 전체</option>
                {/if}
            {/each}
        {/if}
    </select>

    <select
        bind:this={item_sclas_wrap_this}
        class="item-mclas-wrap"
        id="item-mclas-wrap"
        style="width: calc(100%)"
        autocomplete="off"
    >
        {#if item_sclas_wrap_yn}
            <option value="">소분류 전체</option>
        {:else}
            {#each item_sclas_wrap_list as data}
                {#if data.sclas_CODE != "?"}
                    <option value={data.sclas_CODE}>{data.cl_NM}</option>
                {:else}
                    <option value="">소분류 전체</option>
                {/if}
            {/each}
        {/if}
    </select>

    <div class="cmn-btn cmn-btn-btm">
        <button
            on:click={() => {
                push(
                    `/as_item/item_list?item_nm=${item_nm_value}&stndrd=${stndrd_value}&item_lclas_wrap=${item_lclas_wrap_this.value}&item_mlsfc_wrap=${item_mlsfc_wrap_this.value}&item_sclas_wrap=${item_sclas_wrap_this.value}`
                );
            }}>검색</button
        >
    </div>
</section>
