<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import * as api from "../../store/asItem.store";
    import Header from "../../components/Header.svelte";
    import WebSideMenu from "../../components/WebSideMenu.svelte";
    import { push } from "svelte-spa-router";

    // --------------- PC ---------------
    let page = 1; // 현재페이지
    let scrollchk = true; // 스크롤 체크 여부 플래그
    let totallist = ""; // ajax로 받아온 list를 이 변수에 계속 누적시킴
    let mutex = false; // 중복호출 방지 플래그

    let item_list = [];
    let item_list_this;
    let item_list_yn = true;

    let item_nm_value = "";
    let stndrd_value = "";

    let item_lclas_wrap_list = [];
    let item_lclas_wrap_this;

    let item_mlsfc_wrap_list = [];
    let item_mlsfc_wrap_this;
    let item_mlsfc_wrap_yn = true;

    let item_sclas_wrap_list = [];
    let item_sclas_wrap_this;
    let item_sclas_wrap_yn = true;

    let popup_item_dtl_name = "";
    let popup_item_dtl_img = "";

    onMount(() => {
        inin();
        getPageData();
    });

    function itemListScroll() {
        if (scrollchk) {
            if (
                item_list_this.scrollTop + 200 >=
                item_list_this.scrollHeight - item_list_this.clientHeight
            ) {
                if (mutex) {
                    return;
                }
                mutex = true;
                getPageData();
            }
        }
    }

    // 초기화
    async function inin() {
        item_lclas_wrap_list = await api.apiGetAsItemInit();
    }

    // 대분류 선택 시 중분류 데이터 변경
    async function selectLclas() {
        // 대분류 전체 선택 시
        if (item_lclas_wrap_this.value == "") {
            item_mlsfc_wrap_yn = true;
            item_sclas_wrap_yn = true;
            return;
        }

        item_mlsfc_wrap_list = await api.apiGetAsItemInitMlsfcCode(
            item_lclas_wrap_this.value
        );

        if (item_mlsfc_wrap_list.length > 0) {
            item_mlsfc_wrap_yn = false;
        }
    }

    // 중분류 선택 시 소분류 데이터 변경
    async function selectMlsfc() {
        // 중분류 전체 선택 시
        if (item_mlsfc_wrap_this.value == "") {
            item_sclas_wrap_yn = true;
            return;
        }

        item_sclas_wrap_list = await api.apiGetAsItemInitSclasCode(
            item_mlsfc_wrap_this.value
        );

        if (item_sclas_wrap_list.length > 0) {
            item_sclas_wrap_yn = false;
        }
    }

    function search() {
        item_list = [];
        item_list_this.scrollTop = 0;
        page = 1;
        totallist = "";

        getPageData();
    }

    // 검색
    async function getPageData() {
        const response = await api.apiGetAsItemFindAll(
            page,
            item_nm_value,
            stndrd_value,
            item_lclas_wrap_this.value,
            item_mlsfc_wrap_this.value,
            item_sclas_wrap_this.value
        );

        if (response.length == undefined) {
            item_list_yn = true;
            page++; // 데이터를 받아오는데 성공했고 추가할 페이지가 있으면 현재 페이지를 늘려준다.

            item_list = item_list.concat(response.list);

            // 현재 페이지의 주소에 ## 이라는 앵커를 넣어줌으로서 이 페이지에 1회이상 페이지 로딩이 있었다는 표시를 해준다.
            // history.replaceState({list:totallist, page:page},'Page '+page, '/as_item/item_list.html##')

            scrollchk = true; //데이터 로딩이 끝나면 스크롤체크를 풀어준다.
            mutex = false; //데이터로딩이 끝나면 ajax접근권한을 준다(중복호출 방어)
        } else {
            if (item_list.length == 0) {
                item_list_yn = false;
            }
        }
    }

    // 주문담기
    async function cart_item_pc(str) {
        const ITEM_CODE = str;
        const QNTT = document.getElementById(`qntt${str}`).innerText;

        if (isNaN(QNTT) || QNTT === 0) {
            alert("수량이 숫자 형식이 아니거나 0입니다.");
        }

        const response = await api.apiPostAsItemCart(ITEM_CODE, QNTT);
        if (response) {
            document.getElementById(`qntt${str}`).innerText = 1;
        }
    }

    // 문의하기
    function qna_item_pc(str) {
        push(`/as_question/qna_write?item_NM=${str}`);
    }

    // 장바구니 - 품목 수량 빼기
    function item_cart_m_pc(str) {
        const currentValue = parseInt(document.getElementById(str).innerText);

        if (currentValue == 1) {
            alert("1개 이상 주문 가능합니다.");
        } else {
            document.getElementById(str).innerText = currentValue - 1;
        }
    }

    // 장바구니 - 품목 수량 더하기
    function item_cart_p_pc(str) {
        const currentValue = parseInt(document.getElementById(str).innerText);

        document.getElementById(str).innerText = currentValue + 1;
    }

    // 팝업 open
    function show_use_live(name, img) {
        if (img == "resource/img/img_item.png") return;

        popup_item_dtl_name = name;
        popup_item_dtl_img = img;

        document.querySelector(".background").className = "background show-use";
    }

    // 팝업 close
    function close_use_live() {
        document.querySelector(".background").className = "background";
    }

    // ------------- MOBILE -------------
    let page_m = 1; // 현재페이지
    let scrollchk_m = true; // 스크롤 체크 여부 플래그
    let totallist_m = ""; // ajax로 받아온 list를 이 변수에 계속 누적시킴
    let mutex_m = false; // 중복호출 방지 플래그
    let item_list_yn_m = true;
    let item_list_m = [];

    onMount(() => {
        const param = Common.getURLParameters();

        if (param.item_nm != undefined) {
            getPageData_m();

            window.addEventListener("scroll", itemListScroll_m);
        }
    });

    function itemListScroll_m() {
        var documentHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
        );
        var windowHeight =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.getElementsByTagName("body")[0].clientHeight;

        if (scrollchk_m) {
            if (window.scrollY + 200 >= documentHeight - windowHeight) {
                if (mutex_m) {
                    return;
                }
                mutex_m = true;
                getPageData_m();
            }
        }
    }

    // 검색
    async function getPageData_m() {
        const param = Common.getURLParameters();

        const ITEM_NM = param.item_nm;
        const STNDRD = param.stndrd;
        const LCLAS_CODE = param.item_lclas_wrap;
        const MLSFC_CODE = param.item_mlsfc_wrap;
        const SCLAS_CODE = param.item_sclas_wrap;

        const response = await api.apiGetAsItemFindAll(
            page_m,
            ITEM_NM,
            STNDRD,
            LCLAS_CODE,
            MLSFC_CODE,
            SCLAS_CODE
        );

        if (response.length == undefined) {
            item_list_yn_m = true;
            page_m++; // 데이터를 받아오는데 성공했고 추가할 페이지가 있으면 현재 페이지를 늘려준다.

            item_list_m = item_list_m.concat(response.list);

            // 현재 페이지의 주소에 ## 이라는 앵커를 넣어줌으로서 이 페이지에 1회이상 페이지 로딩이 있었다는 표시를 해준다.
            // history.replaceState({list:totallist, page:page},'Page '+page, '/as_item/item_list.html##')

            scrollchk_m = true; //데이터 로딩이 끝나면 스크롤체크를 풀어준다.
            mutex_m = false; //데이터로딩이 끝나면 ajax접근권한을 준다(중복호출 방어)
        } else {
            if (item_list_m.length == 0) {
                item_list_yn_m = false;
            }
        }
    }

    // 주문담기
    async function cart_item(str) {
        const ITEM_CODE = str;
        const QNTT = document.getElementById(`qntt_m${str}`).innerText;

        if (isNaN(QNTT) || QNTT === 0) {
            alert("수량이 숫자 형식이 아니거나 0입니다.");
        }

        const response = await api.apiPostAsItemCart(ITEM_CODE, QNTT);
        if (response) {
            document.getElementById(`qntt_m${str}`).innerText = 1;
        }
    }

    // 문의하기
    function qna_item(str) {
        push(`/as_question/qna_write?item_NM=${str}`);
    }

    // 품목 이미지 상세보기
    function item_dtl(item_NM, photo_FILE) {
        if (photo_FILE == "resource/img/img_item.png") return;

        push(`/as_item/item_dtl?item_NM=${item_NM}&photo_FILE=${photo_FILE}`);
    }

    // 장바구니 - 품목 수량 빼기
    function item_cart_m(str) {
        const currentValue = parseInt(document.getElementById(str).innerText);

        if (currentValue == 1) {
            alert("1개 이상 주문 가능합니다.");
        } else {
            document.getElementById(str).innerText = currentValue - 1;
        }
    }

    // 장바구니 - 품목 수량 더하기
    function item_cart_p(str) {
        const currentValue = parseInt(document.getElementById(str).innerText);

        document.getElementById(str).innerText = currentValue + 1;
    }
</script>

<Header />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<section class="main inner" style="position: relative;">
    <div class="mobile">
        <div class="cmn-title-left" style="margin-top: 20px;">
            <p>A/S 부품 조회</p>
        </div>
        <div class="item-list">
            <ul id="item-list_m">
                {#if item_list_yn_m}
                    {#each item_list_m as data}
                        <li>
                            <p class="item-nm">{data.item_AS_NM}</p>
                            <div class="item-dtl">
                                <div class="item-img">
                                    <img
                                        src={data.photo_FILE == null ||
                                        data.photo_FILE == ""
                                            ? "resource/img/img_item.png"
                                            : Common.imgCnv(data.photo_FILE)}
                                        alt=""
                                    />
                                    <span>
                                        <img
                                            src="resource/img/ico_img_dtl.png"
                                            alt=""
                                            class="img-dtl"
                                            on:click={item_dtl(
                                                `${data.item_AS_NM}`,
                                                `${
                                                    data.photo_FILE == null ||
                                                    data.photo_FILE == ""
                                                        ? "resource/img/img_item.png"
                                                        : Common.imgCnv(
                                                              data.photo_FILE
                                                          )
                                                }`
                                            )}
                                        /></span
                                    >
                                </div>
                                <div class="item-info">
                                    <div class="item-info-wrap">
                                        <p class="info-nm">품목코드</p>
                                        <p class="item-value item-code">
                                            {data.item_CODE}
                                        </p>
                                    </div>
                                    <div class="item-info-wrap">
                                        <p class="info-nm">규격</p>
                                        <p class="item-value item-stndrd">
                                            {data.stndrd == null
                                                ? ""
                                                : data.stndrd}
                                        </p>
                                    </div>
                                    <div class="item-info-wrap">
                                        <p class="info-nm">중분류</p>
                                        <p class="item-value item-sclas">
                                            {data.mlsfc_NM == null
                                                ? ""
                                                : data.mlsfc_NM}
                                        </p>
                                    </div>
                                    <div class="item-info-wrap">
                                        <p class="info-nm">소분류</p>
                                        <p class="item-value item-sclas">
                                            {data.sclas_NM == null
                                                ? ""
                                                : data.sclas_NM}
                                        </p>
                                    </div>
                                    <div class="item-count">
                                        <p
                                            class="count"
                                            style="position: absolute; left: 0;"
                                            on:click={item_cart_m(
                                                `qntt_m${data.item_CODE}`
                                            )}
                                        >
                                            -
                                        </p>
                                        <p
                                            class="item_qy"
                                            id="qntt_m{data.item_CODE}"
                                        >
                                            1
                                        </p>
                                        <p
                                            class="count"
                                            style="position: absolute; right: 0;"
                                            on:click={item_cart_p(
                                                `qntt_m${data.item_CODE}`
                                            )}
                                        >
                                            +
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="item-btn">
                                <div
                                    class="cart-btn"
                                    on:click={cart_item(`${data.item_CODE}`)}
                                >
                                    <p>주문담기</p>
                                </div>
                                <div
                                    class="qna-btn"
                                    on:click={qna_item(`${data.item_AS_NM}`)}
                                >
                                    <p>문의하기</p>
                                </div>
                            </div>
                        </li>
                    {/each}
                {:else}
                    <p>검색 결과가 없습니다.</p>
                {/if}
            </ul>
        </div>
    </div>

    <div class="pc pc-inner">
        <WebSideMenu />

        <div
            class="pc-wh"
            style="width: calc(100% - 360px); margin-left: 370px; margin-top: 40px; margin-bottom: 20px;"
        >
            <div class="cmn-title" style="font-weight: 600; text-align: left;">
                <p>A/S 부품조회</p>
            </div>

            <div class="search-wrap" style="width: 1491px;">
                <div style="width: 95%; margin: 0 auto;">
                    <div>
                        <div style="display: flex; margin-left: 100px;">
                            <div class="join-info" style="margin-right: 70px;">
                                <p
                                    class="join-info-title"
                                    style="width: 70px; margin-right: 15px;"
                                >
                                    대분류
                                </p>
                                <select
                                    bind:this={item_lclas_wrap_this}
                                    class="item-sclas-wrap"
                                    style="width: 270px;"
                                    on:change={selectLclas}
                                    autocomplete="off"
                                >
                                    <option value="">전체</option>
                                    {#each item_lclas_wrap_list as data}
                                        <option value={data.lclas_CODE}
                                            >{data.cl_NM}</option
                                        >
                                    {/each}
                                </select>
                            </div>

                            <div class="join-info" style="margin-right: 70px;">
                                <p
                                    class="join-info-title"
                                    style="width: 70px; margin-right: 15px;"
                                >
                                    중분류
                                </p>
                                <select
                                    bind:this={item_mlsfc_wrap_this}
                                    class="item-sclas-wrap"
                                    style="width: 270px;"
                                    on:change={selectMlsfc}
                                    autocomplete="off"
                                >
                                    {#if item_mlsfc_wrap_yn}
                                        <option value="">전체</option>
                                    {:else}
                                        {#each item_mlsfc_wrap_list as data}
                                            {#if data.mlsfc_CODE != "?"}
                                                <option value={data.mlsfc_CODE}
                                                    >{data.cl_NM}</option
                                                >
                                            {:else}
                                                <option value="">전체</option>
                                            {/if}
                                        {/each}
                                    {/if}
                                </select>
                            </div>

                            <div class="join-info">
                                <p
                                    class="join-info-title"
                                    style="width: 70px; margin-right: 15px;"
                                >
                                    소분류
                                </p>
                                <select
                                    bind:this={item_sclas_wrap_this}
                                    class="item-sclas-wrap"
                                    style="width: 270px;"
                                    autocomplete="off"
                                >
                                    {#if item_sclas_wrap_yn}
                                        <option value="">전체</option>
                                    {:else}
                                        {#each item_sclas_wrap_list as data}
                                            {#if data.sclas_CODE != "?"}
                                                <option value={data.sclas_CODE}
                                                    >{data.cl_NM}</option
                                                >
                                            {:else}
                                                <option value="">전체</option>
                                            {/if}
                                        {/each}
                                    {/if}
                                </select>
                            </div>
                        </div>

                        <div
                            style="display: flex; margin-left: 100px; margin-top: 12px;"
                        >
                            <div class="join-info" style="margin-right: 70px;">
                                <p
                                    class="join-info-title"
                                    style="width: 70px; margin-right: 15px;"
                                >
                                    A/S 품목명
                                </p>
                                <input
                                    bind:value={item_nm_value}
                                    type="text"
                                    id="item_nm"
                                    autocomplete="off"
                                    placeholder="품목명을 입력하세요."
                                    style="width: 252px; margin-right: 0px;"
                                    maxlength="20"
                                    on:keydown={(e) => {
                                        e.key === "Enter" ? search() : "";
                                    }}
                                />
                            </div>

                            <div class="join-info" style="margin-right: 70px;">
                                <p
                                    class="join-info-title"
                                    style="width: 70px; margin-right: 15px;"
                                >
                                    규격
                                </p>
                                <input
                                    bind:value={stndrd_value}
                                    type="text"
                                    id="stndrd"
                                    autocomplete="off"
                                    placeholder="규격을 입력하세요."
                                    style="width: 252px; margin-right: 0px;"
                                    maxlength="20"
                                    on:keydown={(e) => {
                                        e.key === "Enter" ? search() : "";
                                    }}
                                />
                            </div>

                            <div
                                style="height: 46px; display: flex; align-items: center;"
                            >
                                <div
                                    class="cmn-btn cmn-btn-navy"
                                    style="width: 354px; margin-top: 0px;"
                                >
                                    <button on:click={search}>검색</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item-list item-list-3">
                <ul
                    bind:this={item_list_this}
                    on:scroll={itemListScroll}
                    class="scroll-custom"
                    id="item-list"
                >
                    {#if item_list_yn}
                        {#each item_list as data}
                            <li>
                                <div class="item-dtl">
                                    <div class="item-img">
                                        <img
                                            src={data.photo_FILE == null ||
                                            data.photo_FILE == ""
                                                ? "resource/img/img_item.png"
                                                : Common.imgCnv(
                                                      data.photo_FILE
                                                  )}
                                            alt=""
                                        />
                                        <span>
                                            <img
                                                src="resource/img/ico_img_dtl.png"
                                                alt=""
                                                class="img-dtl"
                                                on:click={show_use_live(
                                                    `${data.item_AS_NM}`,
                                                    `${
                                                        data.photo_FILE ==
                                                            null ||
                                                        data.photo_FILE == ""
                                                            ? "resource/img/img_item.png"
                                                            : Common.imgCnv(
                                                                  data.photo_FILE
                                                              )
                                                    }`
                                                )}
                                            /></span
                                        >
                                    </div>
                                    <div class="item-info">
                                        <p class="item-nm">
                                            {data.item_AS_NM}
                                        </p>
                                        <div style="position: relative;">
                                            <div class="item-info-wrap">
                                                <p class="info-nm">품목코드</p>
                                                <p class="item-value item-code">
                                                    {data.item_CODE}
                                                </p>
                                            </div>
                                            <div class="item-info-wrap">
                                                <p class="info-nm">규격</p>
                                                <p
                                                    class="item-value item-stndrd"
                                                >
                                                    {data.stndrd == null
                                                        ? ""
                                                        : data.stndrd}
                                                </p>
                                            </div>
                                            <div class="item-info-wrap">
                                                <p class="info-nm">중분류</p>
                                                <p
                                                    class="item-value item-sclas"
                                                >
                                                    {data.mlsfc_NM == null
                                                        ? ""
                                                        : data.mlsfc_NM}
                                                </p>
                                            </div>
                                            <div class="item-info-wrap">
                                                <p class="info-nm">소분류</p>
                                                <p
                                                    class="item-value item-sclas"
                                                >
                                                    {data.sclas_NM == null
                                                        ? ""
                                                        : data.sclas_NM}
                                                </p>
                                            </div>
                                            <div
                                                class="item-count item-count-r"
                                            >
                                                <p
                                                    class="count"
                                                    style="position: absolute; left: 0;"
                                                    on:click={item_cart_m_pc(
                                                        `qntt${data.item_CODE}`
                                                    )}
                                                >
                                                    -
                                                </p>
                                                <p id="qntt{data.item_CODE}">
                                                    1
                                                </p>
                                                <p
                                                    class="count"
                                                    style="position: absolute; right: 0;"
                                                    on:click={item_cart_p_pc(
                                                        `qntt${data.item_CODE}`
                                                    )}
                                                >
                                                    +
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="item-btn">
                                    <div
                                        class="cart-btn"
                                        on:click={cart_item_pc(
                                            `${data.item_CODE}`
                                        )}
                                    >
                                        <p>주문담기</p>
                                        <img
                                            src="resource/img/ico_cart_pc.png"
                                            alt=""
                                        />
                                    </div>
                                    <div
                                        class="qna-btn"
                                        on:click={qna_item_pc(
                                            `${data.item_AS_NM}`
                                        )}
                                    >
                                        <p>문의하기</p>
                                        <img
                                            src="resource/img/ico_qna_pc.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                            </li>
                        {/each}
                    {:else}
                        <p>검색 결과가 없습니다.</p>
                    {/if}
                </ul>
            </div>

            <div class="background">
                <div class="window">
                    <div class="popup">
                        <img
                            src="resource/img/ico_close.png"
                            alt=""
                            class="popup-close"
                            on:click={close_use_live}
                        />
                        <div class="popup-item-dtl" id="popup-item-dtl">
                            <div>
                                <p>{popup_item_dtl_name}</p>
                                <img
                                    src={popup_item_dtl_img}
                                    alt=""
                                    style="width: 1000px;"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
