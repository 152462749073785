<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import { apiGetLogout } from "../../store/auth.store";
    import * as api from "../../store/join.store";
    import { push } from "svelte-spa-router";

    let pw_ex = Common.getCookie("pw_modify");
    let pw_value = "";
    let pw_check_value = "";

    onMount(() => {
        if (pw_ex == null) {
            alert("잘못된 접근입니다.");
            push("/as_password/pw_modify");
        }

        Common.deleteCookie("pw_modify");
        Common.deleteCookie("pw_modify_end");
    });

    async function logout() {
        await apiGetLogout();
    }

    // 수정하기
    async function pw_modify() {
        if (!pw_value.trim()) {
            alert("비밀번호 를(을) 입력해주세요.");
            return;
        }

        if (pw_value != pw_check_value) {
            alert("동일한 비밀번호를 입력해주세요.");
            return;
        }

        if (pw_value == pw_ex) {
            alert("기존 비밀번호와 동일한 비밀번호입니다.");
            return;
        }

        if (await api.apiPostJoinModifyPw(pw_value)) {
            Common.setCookie("pw_modify_end", pw_value, 1);
            push("/as_password/pw_edit_complete");
        }
    }
</script>

<link rel="stylesheet" href="resource/css/common.css" />
<link rel="stylesheet" href="resource/css/style.css" />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<header class="header">
    <div class="hd-title inner">
        <div class="mobile hd-title-wrap">
            <img
                src="resource/img/ico_arrow_left.png"
                alt=""
                class="m-arrow-l"
                on:click={() => {
                    history.back();
                }}
            />
            <p class="hd-menu-nm">비밀번호 수정</p>
        </div>
    </div>

    <div class="pc hd-inner">
        <img
            src="resource/img/img_logo.png"
            alt=""
            on:click={() => {
                push("/login");
            }}
        />
        <p
            on:click={() => {
                push("/as_cart/cart_list");
            }}
            style="margin-right: 100px; padding-right: 20px; border-right: 1px solid #67737C;"
        >
            장바구니
        </p>
        <p on:click={logout}>로그아웃</p>
    </div>
</header>

<section class="main inner">
    <div class="mobile">
        <div class="cmn-title" style="margin-top: 58px;">
            <p>비밀번호 수정</p>
        </div>
        <div class="cmn-input" style="margin-top: 40px;">
            <p>새 비밀번호</p>
            <input
                bind:value={pw_value}
                type="password"
                id="pw_m"
                placeholder="새 비밀번호를 입력해주세요."
            />
        </div>
        <div class="cmn-input" style="margin-top: 10px;">
            <p>새 비밀번호 확인</p>
            <input
                bind:value={pw_check_value}
                type="password"
                id="pw_check_m"
                placeholder="새 비밀번호를 한번 더 입력해주세요."
            />
        </div>
        <div class="cmn-btn" style="margin-top: 26px;">
            <button on:click={pw_modify}>확인</button>
        </div>
    </div>

    <div class="pc main-pad">
        <div class="pw-find">
            <div>
                <div class="cmn-title" style="font-weight: 600;">
                    <p>비밀번호 수정</p>
                </div>
                <div class="cmn-input" style="margin-top: 72px;">
                    <p>새 비밀번호</p>
                    <input
                        bind:value={pw_value}
                        type="password"
                        id="pw"
                        placeholder="새 비밀번호를 입력해주세요."
                    />
                </div>
                <div class="cmn-input" style="margin-top: 20px;">
                    <p>새 비밀번호 확인</p>
                    <input
                        bind:value={pw_check_value}
                        type="password"
                        id="pw_check"
                        placeholder="새 비밀번호를 한번 더 입력해주세요."
                    />
                </div>
                <div
                    class="cmn-btn cmn-btn-navy"
                    style="width: 424px; margin: 0 auto; margin-top: 22px;"
                >
                    <button on:click={pw_modify}>수정</button>
                </div>
            </div>
        </div>
    </div>
</section>
