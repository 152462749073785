import * as api from "../apis/asOrder.api";

export async function apiGetAsOrderInsert(formData) {
    try {
        const response = await api.apiGetAsOrderInsert(formData);

        if (response.status === 200) {
            if (response.data.code === 200) {
                return true;
            } else {
                alert(response.data.msg);
                return false;
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiGetOrder(sdate, edate, is_status) {
    try {
        const response = await api.apiGetOrder(sdate, edate, is_status);

        if (response.status === 200) {
            if (response.data.code === 200) {
                return response.data.data;
            } else {
                return [];
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiGetOrderSelect(ORDER_ID) {
    try {
        const response = await api.apiGetOrderSelect(ORDER_ID);

        if (response.status === 200) {
            if (response.data.code === 200) {
                return response.data.data;
            } else if (response.data.code === 202) {
                alert("조회할 수 없는 주문입니다.");
                history.back();
            } else {
                return [];
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiPostOrderUpdate(ORDER_ID, IS_STATUS) {
    try {
        const formData = {
            ORDER_ID: ORDER_ID,
            IS_STATUS: IS_STATUS,
        };

        const response = await api.apiPostOrderUpdate(formData);

        if (response.status === 200) {
            if (response.data.code === 200) {
                return true;
            } else {
                alert(response.data.msg);
                return false;
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}
