<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import * as orderApi from "../../store/asOrder.store";
    import Header from "../../components/Header.svelte";
    import WebSideMenu from "../../components/WebSideMenu.svelte";
    import { push } from "svelte-spa-router";

    let order;
    let order_list = [];
    let ORDER_ID = "";
    let total_cate = 0;
    let total_cnt = 0;

    let reg_date = "";
    let pay_se = "";
    let bcnc_nm = "";
    let name = "";
    let addr = "";

    let is_status = 0;

    onMount(() => {
        let param = Common.getURLParameters();
        if (param.order_id != undefined) {
            ORDER_ID = param.order_id;
            getPageData();
        } else {
            alert("잘못된 접근입니다.");
            push("/login");
        }
    });

    // ------------------------------ PC ------------------------------
    // 검색
    async function getPageData() {
        const response = await orderApi.apiGetOrderSelect(ORDER_ID);

        order = response.order;
        order_list = response.orderDtilList;

        reg_date = order.reg_DATE.replace(/\.\d+$/, "").replaceAll("-", ".");
        pay_se = order.pay_SE === "Y" ? "유상" : "무상";
        bcnc_nm = order.bcnc_NM;
        name = order.name;
        addr = order.addr_1 + " " + order.addr_2;
        is_status = order.is_STATUS;

        total_cate = order_list.length;
        total_cnt = 0;

        for (let i = 0; i < order_list.length; i++) {
            total_cnt += order_list[i].qntt;
        }

        console.log(order_list);
    }

    // 주문요청 취소
    async function order_cancel() {
        if (is_status != 1) {
            alert(
                "이미 주문 접수가 확인되었습니다. \n관리자에게 문의해주세요."
            );
            return;
        }

        if (!confirm("주문을 취소하시겠습니까?")) {
            return;
        }

        if (await orderApi.apiPostOrderUpdate(ORDER_ID, "5")) {
            push("/as_cart/cart_order_list");
        }
    }

    // 견적서 다운로드
    function estmt_excel() {
        alert("견적서 다운로드 " + ORDER_ID);
    }

    // 견적서 다운로드 버튼 : 상태값이 견적 확인일 경우에
    // $('.order-btn ').css({'display': 'none'});
    // $('.estmt-btn ').css({'display': 'flex'});
    // ------------------------------ Mobile ------------------------------
</script>

<Header />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<section
    class="main inner main-pad-36 main-gray"
    style="width: 100%; position: relative;"
>
    <div class="mobile">
        <div style=" background: #FFF;">
            <div
                class="cmn-title-left gray-width"
                style="padding-top: 20px; padding-bottom: 15px;"
            >
                <p>A/S 부품 주문내역</p>
            </div>
            <div class="gray-width" style="padding-bottom: 10px;">
                <div class="cart-order-list" style="padding: 0;">
                    <ul>
                        <li style="border-bottom: none;">
                            <div class="list-in">
                                <p class="list-title">주문일자</p>
                                <p id="reg_date_m">{reg_date}</p>
                            </div>
                            <div class="list-in">
                                <p class="list-title">주문담당자</p>
                                <p id="name_m">{name}</p>
                            </div>
                            <div class="list-in">
                                <p class="list-title">주소</p>
                                <p id="addr_m">{addr}</p>
                            </div>
                            <div class="list-in">
                                <p class="list-title">유무상구분</p>
                                <p id="pay_se_m">
                                    {pay_se}
                                </p>
                            </div>
                            <div class="list-in">
                                <p class="list-title">거래처명</p>
                                <p id="bcnc_nm_m">{bcnc_nm}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="item-list" style="margin-top: 8px; background: #FFF;">
            <ul
                class="gray-width"
                id="item-list_m"
                style="padding-top: 17px; padding-bottom: 5px;"
            >
                {#if order_list.length > 0}
                    {#each order_list as data}
                        <li
                            style="padding-bottom: 20px; border-bottom: 1px solid var(--line, #ECECEC);"
                        >
                            <p class="item-nm">{data.item_AS_NM}</p>
                            <div class="item-dtl">
                                <div class="item-img">
                                    <img
                                        src={data.photo_FILE == null ||
                                        data.photo_FILE == ""
                                            ? "resource/img/img_item.png"
                                            : Common.imgCnv(data.photo_FILE)}
                                        alt=""
                                    />
                                </div>
                                <div class="item-info">
                                    <div class="item-info-wrap">
                                        <p class="info-nm">품목코드</p>
                                        <p class="item-value item-code">
                                            {data.item_CODE}
                                        </p>
                                    </div>
                                    <div class="item-info-wrap">
                                        <p class="info-nm">규격</p>
                                        <p class="item-value item-stndrd">
                                            {data.stndrd == null
                                                ? ""
                                                : data.stndrd}
                                        </p>
                                    </div>
                                    <div class="item-info-wrap">
                                        <p class="info-nm">중분류</p>
                                        <p class="item-value item-mclas">
                                            {data.mlsfc_NM == null
                                                ? ""
                                                : data.mlsfc_NM}
                                        </p>
                                    </div>
                                    <div class="item-info-wrap">
                                        <p class="info-nm">소분류</p>
                                        <p class="item-value item-mclas">
                                            {data.sclas_NM == null
                                                ? ""
                                                : data.sclas_NM}
                                        </p>
                                    </div>
                                    <div class="item-info-wrap">
                                        <p class="info-nm">개수</p>
                                        <p class="item-value">{data.qntt}개</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    {/each}
                {:else}
                    <p>검색 결과가 없습니다.</p>
                {/if}
            </ul>
        </div>
    </div>

    <div class="pc pc-inner">
        <WebSideMenu />

        <div
            class="pc-wh"
            style="width: calc(100% - 360px); margin-left: 370px; margin-top: 40px; margin-bottom: 20px;"
        >
            <div class="cmn-title" style="font-weight: 600; text-align: left;">
                <p>A/S 부품 주문 내역</p>
            </div>

            <div class="search-wrap" style="height: 190px;">
                <div style="width: 95%; margin: 0 auto;">
                    <div>
                        <div style="display: flex; justify-content: center;">
                            <div class="join-info" style="margin-right: 50px;">
                                <p class="join-info-title" style="width: 70px;">
                                    주문담당자
                                </p>
                                <input
                                    value={name}
                                    type="text"
                                    id="name"
                                    style="margin-right: 0;"
                                    readonly
                                />
                            </div>

                            <div class="join-info">
                                <p class="join-info-title" style="width: 70px;">
                                    주문일자
                                </p>
                                <input
                                    value={reg_date}
                                    type="text"
                                    id="reg_date"
                                    style="margin-right: 0;"
                                    readonly
                                />
                            </div>
                        </div>

                        <div
                            style="display: flex; justify-content: center; margin-top: 12px;"
                        >
                            <div class="join-info">
                                <p class="join-info-title" style="width: 70px;">
                                    주소
                                </p>
                                <input
                                    value={addr}
                                    type="text"
                                    id="addr"
                                    style="margin-right: 0; width: 746px;"
                                    readonly
                                />
                            </div>
                        </div>

                        <div
                            style="display: flex; justify-content: center; margin-top: 12px;"
                        >
                            <div class="join-info" style="margin-right: 50px;">
                                <p class="join-info-title" style="width: 70px;">
                                    유무상구분
                                </p>
                                <input
                                    value={pay_se}
                                    type="text"
                                    id="pay_se"
                                    style="margin-right: 0;"
                                    readonly
                                />
                            </div>

                            <div class="join-info">
                                <p class="join-info-title" style="width: 70px;">
                                    거래처명
                                </p>
                                <input
                                    value={bcnc_nm}
                                    type="text"
                                    id="bcnc_nm"
                                    style="margin-right: 0;"
                                    readonly
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cart-list-wrap order-list-wrap">
                <div>
                    <ul
                        class="scroll-custom"
                        id="item-list"
                        style="margin-top: 10px; max-height: 410px; width: 1201px;"
                    >
                        {#if order_list.length > 0}
                            {#each order_list as data}
                                <li
                                    class="cart-list order-list"
                                    style="width: 1190px;"
                                >
                                    <div>
                                        <div class="item-dtl">
                                            <div class="item-img">
                                                <img
                                                    src={data.photo_FILE ==
                                                        null ||
                                                    data.photo_FILE == ""
                                                        ? "resource/img/img_item.png"
                                                        : Common.imgCnv(
                                                              data.photo_FILE
                                                          )}
                                                    alt=""
                                                />
                                            </div>
                                            <div
                                                class="item-info cart-item-info"
                                            >
                                                <p class="item-nm">
                                                    {data.item_AS_NM}
                                                </p>
                                                <div class="item-info-wrap">
                                                    <p class="info-nm">
                                                        품목코드
                                                    </p>
                                                    <p
                                                        class="item-value item-code"
                                                    >
                                                        {data.item_CODE}
                                                    </p>
                                                </div>
                                                <div class="item-info-wrap">
                                                    <p class="info-nm">규격</p>
                                                    <p
                                                        class="item-value item-stndrd"
                                                    >
                                                        {data.stndrd == null
                                                            ? ""
                                                            : data.stndrd}
                                                    </p>
                                                </div>
                                                <div class="item-info-wrap">
                                                    <p class="info-nm">
                                                        중분류
                                                    </p>
                                                    <p
                                                        class="item-value item-mclas"
                                                    >
                                                        {data.mlsfc_NM == null
                                                            ? ""
                                                            : data.mlsfc_NM}
                                                    </p>
                                                </div>
                                                <div class="item-info-wrap">
                                                    <p class="info-nm">
                                                        소분류
                                                    </p>
                                                    <p
                                                        class="item-value item-mclas"
                                                    >
                                                        {data.sclas_NM == null
                                                            ? ""
                                                            : data.sclas_NM}
                                                    </p>
                                                </div>
                                                <div class="item-count-num">
                                                    <p>수량&nbsp;</p>
                                                    <span>{data.qntt}개</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            {/each}
                        {:else}
                            <p>검색 결과가 없습니다.</p>
                        {/if}
                    </ul>

                    <div class="cart-sum" style="width: 1201px;">
                        <p style="margin-right: 39px;">
                            <span>총 부품 주문 품목 :&nbsp;</span>
                            <span class="cart-item-sum" id="total_cate"
                                >{total_cate}개</span
                            >
                            <span>&nbsp;&nbsp;&nbsp;&nbsp;- 합계 :&nbsp;</span>
                            <span class="cart-item-sum" id="total_cnt"
                                >{total_cnt}개</span
                            >
                        </p>
                    </div>
                </div>
            </div>

            <div
                class="cmn-btn order-btn"
                style="position: relative; width: 1201px; display: flex;"
            >
                <div style="position: relative; width: 50%;">
                    <button
                        style="position: absolute; right: 5px; width: 284px; margin: 0 auto;"
                        on:click={() => {
                            push("/as_question/qna_write");
                        }}>문의하기</button
                    >
                </div>
                <div style="position: relative; width: 50%;">
                    <button
                        style="position: absolute; left: 5px; width: 284px; margin: 0 auto; background: var(--line, #E54031); color: var(--space-font-color, #FFF);"
                        on:click={order_cancel}>주문요청 취소</button
                    >
                </div>
            </div>

            <div
                class="cmn-btn estmt-btn"
                style="position: relative; width: 1201px; margin: 0 auto; margin-top: 25px; display: flex; display: none;"
            >
                <button
                    style="width: 284px; background: var(--line, #ECECEC); color: var(--space-font-color, #53616C);"
                    on:click={estmt_excel}>견적서 다운로드</button
                >
                <button
                    style="width: 284px; margin-left: 15px; margin-right: 15px;"
                    on:click={() => {
                        push("/as_question/qna_write");
                    }}>문의하기</button
                >
                <button
                    style="width: 284px; background: var(--line, #E54031); color: var(--space-font-color, #FFF);"
                    on:click={order_cancel}>주문요청 취소</button
                >
            </div>
        </div>
    </div>
</section>
