<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import * as api from "../../store/asQuestion.store";
    import Header from "../../components/Header.svelte";
    import WebSideMenu from "../../components/WebSideMenu.svelte";
    import { push } from "svelte-spa-router";

    let INQUIRY_ID = "";

    let title = "";
    let content = "";
    let reg_date = "";
    let qna_img = "";

    let is_status = "";
    let answer = "";
    let answer_date = "";
    let is_status_input = "";

    onMount(() => {
        let item_NM = Common.getURLParameters().item_NM;
        if (item_NM != undefined) {
            title = `${item_NM} 관련 문의`;
        }

        reg_date = Common.getFormatDate("", "-");
    });

    // -------------------------------- PC --------------------------------

    // 등록하기
    async function qna_save() {
        let formData = {
            TITLE: title,
            CONTENT: content.replace(/(?:\r\n|\r|\n)/g, "<br>"),
        };

        if (document.getElementById("upload_path_img").files[0] != undefined) {
            formData.UPLOAD_PATH_IMG =
                document.getElementById("upload_path_img").files[0];
        }

        if (!title.trim()) {
            alert("제목을 입력해주세요.");
            return;
        }

        if (!content.trim()) {
            alert("문의 내용을 입력해주세요.");
            return;
        }

        if (await api.apiPostAsInquiryInsert(formData)) {
            push("/as_question/qna_list");
        }
    }

    function upload_img(event) {
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();

            reader.onload = function (e) {
                qna_img = e.target.result;
            };

            reader.readAsDataURL(event.target.files[0]);
        }
    }
    // ------------------------------ Mobile ------------------------------
    // 등록하기
    async function qna_save_m() {
        let formData = {
            TITLE: title,
            CONTENT: content.replace(/(?:\r\n|\r|\n)/g, "<br>"),
        };

        if (
            document.getElementById("upload_path_img_m").files[0] != undefined
        ) {
            formData.UPLOAD_PATH_IMG =
                document.getElementById("upload_path_img_m").files[0];
        }

        if (!title.trim()) {
            alert("제목을 입력해주세요.");
            return;
        }

        if (!content.trim()) {
            alert("문의 내용을 입력해주세요.");
            return;
        }

        if (await api.apiPostAsInquiryInsert(formData)) {
            push("/as_question/qna_list");
        }
    }
</script>

<Header />

<section class="main inner main-pad-36" style="position: relative;">
    <div class="mobile">
        <div
            style="border-bottom: 1px solid var(--line, #ECECEC); position: relative;"
        >
            <div
                class="cmn-title-left"
                style="padding-top: 20px; padding-bottom: 15px;"
            >
                <p>문의하기</p>
            </div>
            <div style="padding-bottom: 10px;">
                <div class="cart-order-list gray-width" style="padding: 0;">
                    <ul>
                        <li style="border-bottom: none;">
                            <div class="list-in">
                                <p class="list-title">작성일자</p>
                                <p class="regDate">{reg_date}</p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div
            class="cmn-input"
            style="margin-top: 11px; padding-bottom: 11px; border-bottom: 1px solid var(--line, #ECECEC);"
        >
            <p>제목</p>
            <input
                bind:value={title}
                type="text"
                id="title_m"
                placeholder="제목을 입력해주세요."
            />
        </div>
        <div
            class="cmn-textarea"
            style="margin-top: 11px; padding-bottom: 11px; border-bottom: 1px solid var(--line, #ECECEC);"
        >
            <textarea
                bind:value={content}
                placeholder="문의내용을 입력해주세요."
                id="content_m"
                style="margin-top: 0;"
            ></textarea>
        </div>
        <div class="filebox qna-img-btn">
            <input
                class="upload-name"
                value="파일선택"
                disabled="disabled"
                style="display: none;"
            />
            <label for="upload_path_img_m">
                <p>이미지 첨부</p>
                <span><img src="resource/img/ico_photo.png" alt="" /></span>
            </label>
            <input
                type="file"
                id="upload_path_img_m"
                class="upload-hidden"
                accept="image/jpeg,image/png"
                on:change={upload_img}
            />
        </div>
        <div class="qna-img-wrap">
            <img src={qna_img} alt="" class="qna-img" id="qna_img_m" />
        </div>
        <div class="cmn-btn" style="margin-top: 11px;">
            <button on:click={qna_save_m}>등록하기</button>
        </div>
    </div>

    <div class="pc pc-inner">
        <WebSideMenu />

        <div
            class="pc-wh"
            style="width: calc(100% - 360px); margin-left: 370px; margin-top: 40px; margin-bottom: 20px;"
        >
            <div class="cmn-title" style="font-weight: 600; text-align: left;">
                <p>문의하기</p>
            </div>

            <div class="qna-write">
                <div class="qna-write-wrap">
                    <p class="write-title">제목</p>
                    <input
                        bind:value={title}
                        type="text"
                        id="title"
                        class="write-title-in"
                        placeholder="제목입력"
                    />
                    <p class="regDate">{reg_date}</p>
                </div>
                <div class="qna-contents">
                    <textarea
                        bind:value={content}
                        class="scroll-custom"
                        id="content"
                        placeholder="문의내용을 입력해주세요."
                    ></textarea>
                </div>
            </div>

            <div
                class="qna-write scroll-custom"
                style="margin-top: 10px; overflow-y: scroll;"
            >
                <div class="filebox qna-img-btn">
                    <input
                        class="upload-name"
                        value="파일선택"
                        disabled="disabled"
                        style="display: none;"
                    />
                    <label for="upload_path_img">
                        <p>이미지 첨부</p>
                        <span
                            ><img
                                src="resource/img/ico_photo.png"
                                alt=""
                            /></span
                        >
                    </label>
                    <input
                        type="file"
                        id="upload_path_img"
                        class="upload-hidden"
                        accept="image/jpeg,image/png"
                        on:change={upload_img}
                    />
                </div>

                <div class="qna-img">
                    <img src={qna_img} alt="" id="qna_img" />
                </div>
            </div>

            <div
                class="cmn-btn"
                style="position: relative; width: 1201px; display: flex;"
            >
                <div style="position: relative; width: 100%;">
                    <button
                        style="width: 284px; margin: 0 auto;"
                        on:click={qna_save}>등록하기</button
                    >
                </div>
            </div>
        </div>
    </div>
</section>
