import axios from "../utiles/axios.util";

function apiGetAsInquiryFindAll(page, sdate, edate) {
    return axios.get(
        `/api/v1/asInquiry/findAll?page=${page}&sdate=${sdate}&edate=${edate}`
    );
}

function apiGetAsInquirySelect(INQUIRY_ID) {
    return axios.get(`/api/v1/asInquiry/select?INQUIRY_ID=${INQUIRY_ID}`);
}

function apiPostAsInquiryDelete(formData) {
    return axios.post("/api/v1/asInquiry/delete", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

function apiPostAsInquiryInsert(formData) {
    return axios.post("/api/v1/asInquiry/insert", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export {
    apiGetAsInquiryFindAll,
    apiGetAsInquirySelect,
    apiPostAsInquiryDelete,
    apiPostAsInquiryInsert,
};
