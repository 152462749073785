<script>
    import * as Common from "../../utiles/common.util";
    import { onMount } from "svelte";
    import * as api from "../../store/auth.store";
    import { push } from "svelte-spa-router";

    onMount(() => {
        // 로그인 상태 체크
        api.apiGetStatus();

        if (
            Common.getCookie("BIZ_NO") == null ||
            Common.getCookie("PW") == null ||
            Common.getCookie("EMAIL") == null
        ) {
            alert("잘못된 접근입니다.");
            push("/login");
        }
    });
</script>

<link rel="stylesheet" href="resource/css/common.css" />
<link rel="stylesheet" href="resource/css/style.css" />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<header class="pc header">
    <div class="pc hd-inner">
        <img
            src="resource/img/img_logo.png"
            alt=""
            on:click={() => {
                push("/login");
            }}
        />
    </div>
</header>

<section class="main inner">
    <div class="mobile">
        <div class="cmn-title" style="margin-top: 114px;">
            <p>회원가입 완료!</p>
        </div>
        <div class="join-wrap">
            <img src="resource/img/img_join.png" alt="" class="m-join" />
        </div>
        <div class="join-info">
            <p>회원가입이 완료되었습니다!</p>
            <p><span>승인까지 1~2일 소요됩니다.</span> 감사합니다.</p>
        </div>
        <div class="cmn-btn" style="margin-top: 26px;">
            <button
                on:click={() => {
                    push("/login");
                }}>Login</button
            >
        </div>
    </div>

    <div class="pc main-pad">
        <div style="margin-top: 158px;">
            <div class="cmn-title" style="font-weight: 600;">
                <p>회원가입 완료</p>
            </div>
            <div class="mail-wrap">
                <img
                    src="resource/img/img_join.png"
                    alt=""
                    class="m-mail"
                    style="width: 356px; height: 268px;"
                />
            </div>
            <div class="mail-info">
                <p>
                    <span>회원가입이 완료되었습니다.</span><br />
                    <span>승인까지 1~2일 소요됩니다. 감사합니다.</span><br />
                </p>
            </div>
            <div
                class="cmn-btn cmn-btn-navy"
                style="width: 424px; margin: 0 auto; margin-top: 39px;"
            >
                <button
                    on:click={() => {
                        push("/login");
                    }}>메인화면 가기</button
                >
            </div>
        </div>
    </div>
</section>
