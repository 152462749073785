<script>
    import * as Common from "../../utiles/common.util";
    import WebSideMenu from "../../components/WebSideMenu.svelte";
    import { apiGetStatus, apiGetLogout } from "../../store/auth.store";
    import { onMount } from "svelte";
    import { push } from "svelte-spa-router";

    onMount(() => {
        // 로그인 상태 체크
        apiGetStatus();
    });

    async function logout() {
        await apiGetLogout();
    }
</script>

<link rel="stylesheet" href="resource/css/common.css" />
<link rel="stylesheet" href="resource/css/style.css" />

<header class="header">
    <div class="mobile inner">
        <div class="logo-wrap">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <img
                src="resource/img/img_logo.png"
                class="m-logo"
                on:click={() => {
                    push("/as_home/home");
                }}
                alt=""
            />
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <img
                src="resource/img/ico_home.png"
                class="m-home"
                on:click={() => {
                    push("/as_home/home");
                }}
                alt=""
            />
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <img
                src="resource/img/ico_cart.png"
                class="m-cart"
                on:click={() => {
                    push("/as_cart/cart_list");
                }}
                alt=""
            />
        </div>
    </div>
    <div class="pc hd-inner">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <img
            src="resource/img/img_logo.png"
            on:click={() => {
                push("/as_home/home");
            }}
            alt=""
        />
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <p
            on:click={() => {
                push("/as_cart/cart_list");
            }}
            style="margin-right: 100px; padding-right: 20px; border-right: 1px solid #67737C;"
        >
            장바구니
        </p>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <p on:click={logout}>로그아웃</p>
    </div>
</header>

<section class="main inner">
    <div class="mobile">
        <div class="cmn-title" style="margin-top: 58px;">
            <p>프라임 A/S 부품 주문</p>
            <p class="cmn-title-gray" style="margin-top: 8px;">
                오신 것을 환영합니다 !
            </p>
        </div>

        <div class="menu-list" style="margin-top: 57px;">
            <div class="menu-wrap" style="margin-right: 12px;">
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <div
                    class="menu-inner"
                    on:click={() => {
                        push("/as_item/item_search");
                    }}
                >
                    <img src="resource/img/img_menu_list_1.png" alt="" />
                    <p>A/S부품내역</p>
                </div>
            </div>
            <div class="menu-wrap">
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <div
                    class="menu-inner"
                    on:click={() => {
                        push("/as_cart/cart_list");
                    }}
                >
                    <img src="resource/img/img_menu_list_2.png" alt="" />
                    <p>A/S부품 장바구니</p>
                </div>
            </div>
        </div>

        <div class="menu-list" style="margin-top: 10px;">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
                class="menu-wrap"
                style="margin-right: 12px;"
                on:click={() => {
                    push("/as_cart/cart_order_list");
                }}
            >
                <div class="menu-inner">
                    <img src="resource/img/img_menu_list_3.png" alt="" />
                    <p>주문내역</p>
                </div>
            </div>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
                class="menu-wrap"
                on:click={() => {
                    push("/as_question/qna_list");
                }}
            >
                <div class="menu-inner">
                    <img src="resource/img/img_menu_list_4.png" alt="" />
                    <p>문의내역</p>
                </div>
            </div>
        </div>

        <div class="menu-list" style="margin-top: 10px;">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div
                class="menu-wrap"
                style="margin-right: 12px;"
                on:click={() => {
                    push("/as_password/pw_modify");
                }}
            >
                <div class="menu-inner">
                    <img src="resource/img/img_menu_list_5.png" alt="" />
                    <p>비밀번호 변경</p>
                </div>
            </div>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <div class="menu-wrap" on:click={logout}>
                <div class="menu-inner">
                    <img src="resource/img/img_menu_list_6.png" alt="" />
                    <p>로그아웃</p>
                </div>
            </div>
        </div>
    </div>

    <div class="pc pc-inner">
        <WebSideMenu />

        <div class="pc-wh">
            <div class="cmn-title" style="font-weight: 600;">
                <p>프라임 A/S 부품주문</p>
            </div>
            <div class="home-list">
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <ul style="margin-top: 76px;">
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <li
                        on:click={() => {
                            push("/as_item/item_list");
                        }}
                    >
                        <div>
                            <img src="resource/img/img_home_1.png" alt="" />
                            <p>A/S 부품내역</p>
                        </div>
                    </li>
                    <li
                        on:click={() => {
                            push("/as_cart/cart_list");
                        }}
                    >
                        <div>
                            <img src="resource/img/img_home_2.png" alt="" />
                            <p>A/S 부품 장바구니</p>
                        </div>
                    </li>
                    <li
                        on:click={() => {
                            push("/as_cart/cart_order_list");
                        }}
                    >
                        <div>
                            <img src="resource/img/img_home_3.png" alt="" />
                            <p>주문내역</p>
                        </div>
                    </li>
                </ul>
                <ul style="margin-top: 39px;">
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <li
                        on:click={() => {
                            push("/as_question/qna_list");
                        }}
                    >
                        <div>
                            <img src="resource/img/img_home_4.png" alt="" />
                            <p>문의내역</p>
                        </div>
                    </li>
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <li
                        on:click={() => {
                            push("/as_password/pw_modify");
                        }}
                    >
                        <div>
                            <img src="resource/img/img_home_5.png" alt="" />
                            <p>비밀번호 변경</p>
                        </div>
                    </li>
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <li on:click={logout}>
                        <div>
                            <img src="resource/img/img_home_6.png" alt="" />
                            <p>로그아웃</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
