<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import * as api from "../../store/join.store";
    import * as auth_api from "../../store/auth.store";
    import { push } from "svelte-spa-router";

    let email_value = "";
    let email_this;

    let email_check_value = "";
    let email_check_this;

    let mail_confirm = false;

    onMount(() => {
        // 로그인 상태 체크
        auth_api.apiGetStatus();

        if (
            Common.getCookie("BIZ_NO") == null ||
            Common.getCookie("PW") == null
        ) {
            alert("잘못된 접근입니다.");
            push("/login");
        }
    });

    // 2. 이메일 인증요청
    async function mail_send() {
        let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (Common.nullCheck(email_value, "email", "이메일")) return;

        if (!emailRegex.test(email_value)) {
            alert("이메일 형식이 올바르지 않습니다.");
            email_this.focus();
            return;
        }

        if (await api.apiFindByEmail(email_value)) {
            mail_confirm = true;
            startTimer();
            setTimeout(function () {
                email_check_this.focus();
            }, 500);
        } else {
            email_this.focus();
        }
    }

    // 타이머
    let intervalId;

    function startTimer() {
        let time = 179; // 2분 59초

        function updateTimer() {
            const minutes = Math.floor(time / 60);
            const seconds = time % 60;

            const timerElement = document.getElementById("timer");
            timerElement.textContent = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

            if (time === 0) {
                clearInterval(intervalId);
                alert("인증시간이 만료되었습니다.");
                mail_confirm = false;
                email_check_value = "";
            } else {
                time--;
            }
        }

        // 초기 호출
        updateTimer();

        // 이전 타이머 정리
        clearInterval(intervalId);

        // 1초마다 타이머 갱신
        intervalId = setInterval(updateTimer, 1000);
    }

    // 3. 이메일 인증확인
    async function fn_mail_confirm() {
        if (!mail_confirm) {
            alert("이메일 인증을 먼저 진행해주세요.");
            email_this.focus();
            return;
        }

        if (
            Common.nullCheck(
                email_check_value,
                "email_check",
                "이메일 인증번호"
            )
        )
            return;

        if (await api.apiEmailAuth(email_check_value)) {
            clearInterval(intervalId);

            Common.setCookie("EMAIL", email_value, 1);
            push("/as_join/join_photo");
        } else {
            email_check_this.focus();
        }
    }
</script>

<link rel="stylesheet" href="resource/css/common.css" />
<link rel="stylesheet" href="resource/css/style.css" />

<header class="header">
    <div class="hd-title inner">
        <div class="hd-title-wrap">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <img
                src="resource/img/ico_arrow_left.png"
                alt=""
                class="m-arrow-l"
                on:click={() => {
                    history.back();
                }}
            />
            <p class="hd-menu-nm">회원가입</p>
        </div>
    </div>
</header>

<section class="main inner">
    <div class="cmn-title" style="margin-top: 58px;">
        <p>회원가입</p>
    </div>

    <div class="cmn-input" style="margin-top: 40px;">
        <p>이메일 인증</p>
        <div class="cmn-input-btn">
            <input
                bind:value={email_value}
                bind:this={email_this}
                type="text"
                id="email"
                placeholder="이메일을 입력해주세요."
            />
            <button on:click={mail_send}>인증요청</button>
        </div>
        <div
            class="mail-num"
            style={mail_confirm ? "display: flex;" : "display: none"}
        >
            <input
                bind:value={email_check_value}
                bind:this={email_check_this}
                type="text"
                id="email_check"
                placeholder="인증번호"
            />
            <span id="timer">03:00</span>
        </div>
    </div>

    <div class="cmn-btn" style="margin-top: 26px;">
        <button on:click={fn_mail_confirm}>확인</button>
    </div>
</section>
