<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import * as api from "../../store/join.store";
    import * as auth_api from "../../store/auth.store";
    import { push } from "svelte-spa-router";

    let upload_name_1_value = "파일선택";
    let upload_name_2_value = "파일선택";

    let byte_bsns_lcns_value = undefined;
    let byte_copy_acnt_value = undefined;

    onMount(() => {
        // 로그인 상태 체크
        auth_api.apiGetStatus();

        if (
            Common.getCookie("BIZ_NO") == null ||
            Common.getCookie("PW") == null ||
            Common.getCookie("EMAIL") == null
        ) {
            alert("잘못된 접근입니다.");
            push("/login");
        }
    });

    // 사업자등록증 첨부
    function func_upload_1(event) {
        if (window.FileReader) {
            upload_name_1_value = event.target.files[0].name;
        } else {
            upload_name_1_value = event.target.value
                .split("/")
                .pop()
                .split("\\")
                .pop(); // 파일명만 추출
        }
        byte_bsns_lcns_value = event.target.files[0];
    }

    // 통장사본 첨부
    function func_upload_2(event) {
        if (window.FileReader) {
            upload_name_2_value = event.target.files[0].name;
        } else {
            upload_name_2_value = event.target.value
                .split("/")
                .pop()
                .split("\\")
                .pop(); // 파일명만 추출
        }
        byte_copy_acnt_value = event.target.files[0];
    }

    // 가입하기
    async function join() {
        if (byte_bsns_lcns_value == undefined) {
            alert("사업자등록증 이미지를 첨부해주세요.");
            return;
        }

        if (byte_copy_acnt_value == undefined) {
            alert("통장사본 이미지를 첨부해주세요.");
            return;
        }

        if (
            await api.apiPostJoin(
                Common.getCookie("BIZ_NO"),
                Common.getCookie("PW"),
                Common.getCookie("EMAIL"),
                byte_bsns_lcns_value,
                byte_copy_acnt_value,
                byte_bsns_lcns_value.name.substring(
                    byte_bsns_lcns_value.name.lastIndexOf(".") + 1
                ),
                byte_copy_acnt_value.name.substring(
                    byte_copy_acnt_value.name.lastIndexOf(".") + 1
                ),
                Common.getCookie("BSN_CHARGER"),
                Common.getCookie("BPLC_ZIP_CODE"),
                Common.getCookie("BPLC_ADRES_1"),
                Common.getCookie("BPLC_ADRES_2"),
                Common.getCookie("BSN_CHARGER_TELNO")
            )
        ) {
            push("/as_join/join_complete");
        }
    }
</script>

<link rel="stylesheet" href="resource/css/common.css" />
<link rel="stylesheet" href="resource/css/style.css" />

<header class="header">
    <div class="hd-title inner">
        <div class="hd-title-wrap">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <img
                src="resource/img/ico_arrow_left.png"
                alt=""
                class="m-arrow-l"
                on:click={() => {
                    history.back();
                }}
            />
            <p class="hd-menu-nm">회원가입</p>
        </div>
    </div>
</header>

<section class="main inner">
    <div class="cmn-title" style="margin-top: 58px;">
        <p>인증 자료 첨부</p>
    </div>

    <div class="cmn-input" style="margin-top: 40px;">
        <p>사업자 등록증 첨부</p>

        <div class="filebox">
            <input
                bind:value={upload_name_1_value}
                class="upload-name"
                disabled="disabled"
            />
            <label for="byte_bsns_lcns">사진첨부</label>
            <input
                on:change={func_upload_1}
                type="file"
                id="byte_bsns_lcns"
                class="upload-hidden"
                accept="image/jpeg,image/png"
            />
        </div>

        <p style="margin-top: 20px;">통장사본 첨부</p>

        <div class="filebox-2">
            <input
                bind:value={upload_name_2_value}
                class="upload-name-2"
                disabled="disabled"
            />
            <label for="byte_copy_acnt">사진첨부</label>
            <input
                on:change={func_upload_2}
                type="file"
                id="byte_copy_acnt"
                class="upload-hidden-2"
                accept="image/jpeg,image/png"
            />
        </div>
    </div>

    <div class="cmn-btn" style="margin-top: 26px;">
        <button on:click={join}>확인</button>
    </div>
</section>
