<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import * as api from "../../store/join.store";
    import * as auth_api from "../../store/auth.store";
    import { push } from "svelte-spa-router";

    let id_check = false;
    let email_check = false;

    let biz_no_m_value = "";
    let biz_no_m_this;

    let biz_no_value = "";
    let biz_no_this;

    let pw_value = "";
    let pw_this;

    let pw_check_value = "";
    let pw_check_this;

    let email_value = "";
    let email_this;
    let email_yn = false;

    let mail_confirm = false;
    let mail_send_yn = false;
    let mail_send_text = "인증요청";

    let email_check_value = "";
    let email_check_this;

    let upload_name_1_value = "파일선택";
    let upload_name_2_value = "파일선택";

    let byte_bsns_lcns_value = undefined;
    let byte_copy_acnt_value = undefined;

    let bsn_charger_value = "";
    let bplc_zip_code_value = "";
    let bplc_adres_1_value = "";
    let bplc_adres_2_value = "";
    let bsn_charger_telno_value = "";

    let bsn_charger_this;
    let bplc_adres_2_this;
    let bsn_charger_telno_this;

    let bsn_charger_m_value = "";
    let bplc_zip_code_m_value = "";
    let bplc_adres_1_m_value = "";
    let bplc_adres_2_m_value = "";
    let bsn_charger_telno_m_value = "";

    let bsn_charger_m_this;
    let bsn_charger_telno_m_this;
    let bplc_adres_2_m_this;

    onMount(() => {
        // 로그인 상태 체크
        auth_api.apiGetStatus();
    });

    // ------------- MOBILE -------------
    // 1. 아이디(사업자등록번호) 중복확인 완료
    async function join_m() {
        if (Common.nullCheck(biz_no_m_value, "biz_no_m", "사업자등록번호"))
            return;

        if (biz_no_m_value.length != 10) {
            alert("사업자등록번호는 10자리 입니다.");
            biz_no_m_this.focus();
            return;
        }

        const regex = /^\d{10}$/;

        if (!regex.test(biz_no_m_value)) {
            alert("사업자등록번호는 숫자만 입력해주세요.");
            biz_no_m_this.focus();
            return;
        }

        if (!bsn_charger_m_value) {
            alert("담당자명을 입력해주세요.");
            bsn_charger_m_this.focus();
            return;
        }

        if (!bsn_charger_telno_m_value) {
            alert("담당자 연락처를 입력해주세요.");
            bsn_charger_telno_m_this.focus();
            return;
        }

        if (!bplc_zip_code_m_value) {
            alert("주소를 입력해주세요.");
            kakaopost_m();
            return;
        }

        if (await api.apiFindByBizNo(biz_no_m_value)) {
            Common.setCookie("BIZ_NO", biz_no_m_value, 1);
            Common.setCookie("BSN_CHARGER", bsn_charger_m_value, 1);
            Common.setCookie("BSN_CHARGER_TELNO", bsn_charger_telno_m_value, 1);
            Common.setCookie("BPLC_ADRES_1", bplc_adres_1_m_value, 1);
            Common.setCookie("BPLC_ADRES_2", bplc_adres_2_m_value, 1);
            Common.setCookie("BPLC_ZIP_CODE", bplc_zip_code_m_value, 1);
            push("/as_join/join_pw");
        } else {
            biz_no_m_this.focus();
        }
    }

    function kakaopost_m() {
        new daum.Postcode({
            oncomplete: function (data) {
                console.log(data);
                bplc_zip_code_m_value = data.zonecode;
                bplc_adres_1_m_value = data.address;
                bplc_adres_2_m_this.focus();
            },
        }).open();
    }

    function formatInput_tel_m(event) {
        bsn_charger_telno_m_value = event.target.value.replace(/-/g, "");
    }

    function formatInput_tel(event) {
        bsn_charger_telno_value = event.target.value.replace(/-/g, "");
    }

    // --------------- PC ---------------
    // 1. 아이디(사업자등록번호) 중복확인
    async function id_confirm() {
        if (Common.nullCheck(biz_no_value, "biz_no", "사업자등록번호")) return;
        if (biz_no_value.length != 10) {
            alert("사업자등록번호는 10자리 입니다.");
            biz_no_this.focus();
            return;
        }

        const regex = /^\d{10}$/;

        if (!regex.test(biz_no_value)) {
            alert("사업자등록번호는 숫자만 입력해주세요.");
            biz_no_this.focus();
            return;
        }

        if (await api.apiFindByBizNo(biz_no_value)) {
            id_check = true;
            pw_this.focus();
        } else {
            biz_no_this.focus();
        }
    }

    // 2. 이메일 인증요청
    async function mail_send() {
        let emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (Common.nullCheck(email_value, "email", "이메일")) return;

        if (!emailRegex.test(email_value)) {
            alert("이메일 형식이 올바르지 않습니다.");
            email_this.focus();
            return;
        }

        if (await api.apiFindByEmail(email_value)) {
            mail_confirm = true;
            startTimer();
            setTimeout(function () {
                email_check_this.focus();
            }, 500);
        } else {
            email_this.focus();
        }
    }

    // 타이머
    let intervalId;

    function startTimer() {
        let time = 179; // 2분 59초

        function updateTimer() {
            const minutes = Math.floor(time / 60);
            const seconds = time % 60;

            const timerElement = document.getElementById("timer");
            timerElement.textContent = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;

            if (time === 0) {
                clearInterval(intervalId);
                alert("인증시간이 만료되었습니다.");
                mail_confirm = false;
                email_check_value = "";
            } else {
                time--;
            }
        }

        // 초기 호출
        updateTimer();

        // 이전 타이머 정리
        clearInterval(intervalId);

        // 1초마다 타이머 갱신
        intervalId = setInterval(updateTimer, 1000);
    }

    // 3. 이메일 인증확인
    async function fn_mail_confirm() {
        if (Common.nullCheck(email_check_value, "email_check", "이메일"))
            return;

        if (await api.apiEmailAuth(email_check_value)) {
            email_check = true;
            mail_confirm = false;
            mail_send_yn = true;
            mail_send_text = "인증 완료";
            email_yn = true;
            email_check_value = "";
            clearInterval(intervalId);
        } else {
            email_check_this.focus();
        }
    }

    // 4. 사업자등록증 첨부
    function func_upload_1(event) {
        if (window.FileReader) {
            upload_name_1_value = event.target.files[0].name;
        } else {
            upload_name_1_value = event.target.value
                .split("/")
                .pop()
                .split("\\")
                .pop(); // 파일명만 추출
        }
        byte_bsns_lcns_value = event.target.files[0];
    }

    // 5. 통장사본 첨부
    function func_upload_2(event) {
        if (window.FileReader) {
            upload_name_2_value = event.target.files[0].name;
        } else {
            upload_name_2_value = event.target.value
                .split("/")
                .pop()
                .split("\\")
                .pop(); // 파일명만 추출
        }
        byte_copy_acnt_value = event.target.files[0];
    }

    // // 6. 가입하기
    async function join() {
        if (!id_check) {
            alert("아이디 중복확인을 해주세요.");
            return;
        }

        if (Common.nullCheck(pw_value, "pw", "비밀번호")) return;
        if (Common.nullCheck(pw_check_value, "pw_check", "비밀번호 확인"))
            return;
        if (Common.nullCheck(bsn_charger_value, "bsn_charger", "담당자명"))
            return;
        if (
            Common.nullCheck(
                bsn_charger_telno_value,
                "bsn_charger_telno",
                "담당자 연락처"
            )
        )
            return;
        if (!bplc_adres_1_value) {
            alert("우편번호 및 도로명 주소를 입력해주세요.");
            return;
        }

        if (pw_value != pw_check_value) {
            alert("동일한 비밀번호를 입력해주세요.");
            pw_check_this.focus();
            return;
        }

        if (!email_check) {
            alert("이메일 인증을 해주세요.");
            return;
        }

        if (byte_bsns_lcns_value == undefined) {
            alert("사업자등록증 이미지를 첨부해주세요.");
            return;
        }

        if (byte_copy_acnt_value == undefined) {
            alert("통장사본 이미지를 첨부해주세요.");
            return;
        }

        Common.setCookie("BIZ_NO", biz_no_m_value, 1);

        if (
            await api.apiPostJoin(
                biz_no_value,
                pw_value,
                email_value,
                byte_bsns_lcns_value,
                byte_copy_acnt_value,
                byte_bsns_lcns_value.name.substring(
                    byte_bsns_lcns_value.name.lastIndexOf(".") + 1
                ),
                byte_copy_acnt_value.name.substring(
                    byte_copy_acnt_value.name.lastIndexOf(".") + 1
                ),
                bsn_charger_value,
                bplc_zip_code_value,
                bplc_adres_1_value,
                bplc_adres_2_value,
                bsn_charger_telno_value
            )
        ) {
            Common.setCookie("BIZ_NO", biz_no_value, 1);
            Common.setCookie("PW", pw_value, 1);
            Common.setCookie("EMAIL", email_value, 1);

            push("/as_join/join_complete");
        }
    }

    function kakaopost() {
        new daum.Postcode({
            oncomplete: function (data) {
                console.log(data);
                bplc_zip_code_value = data.zonecode;
                bplc_adres_1_value = data.address;
                bplc_adres_2_this.focus();
            },
        }).open();
    }

    function formatInput_m(event) {
        biz_no_m_value = event.target.value.replace(/-/g, "");
    }

    function formatInput(event) {
        biz_no_value = event.target.value.replace(/-/g, "");
    }
</script>

<svelte:head>
    <script
        src="//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"
    ></script>
</svelte:head>

<link rel="stylesheet" href="resource/css/common.css" />
<link rel="stylesheet" href="resource/css/style.css" />

<header class="header">
    <div class="mobile hd-title inner">
        <div class="hd-title-wrap">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <img
                src="resource/img/ico_arrow_left.png"
                class="m-arrow-l"
                on:click={() => {
                    push("/login");
                }}
                alt=""
            />
            <p class="hd-menu-nm">회원가입</p>
        </div>
    </div>
    <div class="pc hd-inner">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <img
            src="resource/img/img_logo.png"
            on:click={() => {
                push("/login");
            }}
            alt=""
        />
    </div>
</header>

<section class="main inner">
    <div class="mobile">
        <!-- <div class="cmn-title" style="margin-top: 30px;">
            <p>회원가입</p>
        </div> -->
        <div class="cmn-input" style="margin-top: 40px;">
            <p>아이디(사업자등록번호)</p>
            <input
                type="text"
                id="biz_no_m"
                bind:value={biz_no_m_value}
                bind:this={biz_no_m_this}
                on:input={formatInput_m}
                placeholder="사업자등록번호를 입력해주세요."
            />
        </div>
        <div class="cmn-input" style="margin-top: 30px;">
            <p>담당자명</p>
            <input
                type="text"
                id="bsn_charger_m"
                bind:value={bsn_charger_m_value}
                bind:this={bsn_charger_m_this}
                placeholder="담당자명을 입력해주세요."
            />
        </div>
        <div class="cmn-input" style="margin-top: 30px;">
            <p>담당자 연락처</p>
            <input
                type="text"
                id="bsn_charger_telno_m"
                bind:value={bsn_charger_telno_m_value}
                bind:this={bsn_charger_telno_m_this}
                on:input={formatInput_tel_m}
                placeholder="담당자 연락처를 입력해주세요."
            />
        </div>
        <div class="cmn-input" style="margin-top: 30px;">
            <p>주소</p>
            <input
                type="text"
                id="bplc_adres_1_m"
                bind:value={bplc_adres_1_m_value}
                placeholder="도로명 주소"
                readonly
                on:click={kakaopost_m}
            />
            <input
                type="text"
                id="bplc_adres_2_m"
                bind:value={bplc_adres_2_m_value}
                bind:this={bplc_adres_2_m_this}
                placeholder="상세주소를 입력해주세요."
            />
        </div>
        <div class="cmn-btn" style="margin-top: 30px;">
            <button on:click={join_m}>확인</button>
        </div>
    </div>

    <div class="pc main-pad">
        <div class="pw-find" style="height: 900px; margin-top: 100px;">
            <div>
                <div class="join-info">
                    <p class="join-info-title">아이디(사업자등록번호)</p>
                    <input
                        type="text"
                        bind:value={biz_no_value}
                        bind:this={biz_no_this}
                        on:input={formatInput}
                        id="biz_no"
                        placeholder="사업자등록번호를 입력하세요."
                        readonly={id_check ? true : false}
                        maxlength="20"
                    />

                    <button
                        on:click={id_confirm}
                        style={id_check ? "display: none" : "display: block"}
                        >중복확인</button
                    >
                </div>
                <div class="join-info" style="margin-top: 22px;">
                    <p class="join-info-title">비밀번호</p>
                    <input
                        type="password"
                        bind:value={pw_value}
                        bind:this={pw_this}
                        id="pw"
                        placeholder="비밀번호를 입력하세요."
                        maxlength="20"
                    />
                </div>
                <div class="join-info" style="margin-top: 22px;">
                    <p class="join-info-title">비밀번호 확인</p>
                    <input
                        type="password"
                        bind:value={pw_check_value}
                        bind:this={pw_check_this}
                        id="pw_check"
                        placeholder="비밀번호를 한번 더 입력하세요."
                        maxlength="20"
                    />
                </div>
                <div class="join-info" style="margin-top: 22px;">
                    <p class="join-info-title">담당자명</p>
                    <input
                        type="text"
                        bind:value={bsn_charger_value}
                        bind:this={bsn_charger_this}
                        id="bsn_charger"
                        placeholder="담당자명을 입력하세요."
                        maxlength="10"
                    />
                </div>
                <div class="join-info" style="margin-top: 22px;">
                    <p class="join-info-title">담당자 연락처</p>
                    <input
                        type="text"
                        bind:value={bsn_charger_telno_value}
                        bind:this={bsn_charger_telno_this}
                        on:input={formatInput_tel}
                        id="bsn_charger_telno"
                        placeholder="담당자 연락처를 입력하세요. ('-' 제외)"
                        maxlength="11"
                    />
                </div>
                <div class="join-info" style="margin-top: 22px;">
                    <p class="join-info-title">주소</p>
                    <input
                        type="text"
                        bind:value={bplc_zip_code_value}
                        id="bplc_zip_code"
                        placeholder="우편번호"
                        readonly
                        style="cursor: pointer;"
                        on:click={kakaopost}
                    />
                </div>
                <div class="join-info" style="margin-top: 22px;">
                    <p class="join-info-title"></p>
                    <input
                        type="text"
                        bind:value={bplc_adres_1_value}
                        id="bplc_adres_1"
                        placeholder="도로명 주소"
                        readonly
                        style="cursor: pointer;"
                        on:click={kakaopost}
                    />
                    <input
                        type="text"
                        bind:value={bplc_adres_2_value}
                        bind:this={bplc_adres_2_this}
                        id="bplc_adres_2"
                        placeholder="상세주소를 입력해주세요."
                        style="width: 200px;"
                    />
                </div>
                <div class="join-info" style="margin-top: 22px;">
                    <p class="join-info-title">이메일</p>
                    <input
                        type="text"
                        bind:value={email_value}
                        bind:this={email_this}
                        id="email"
                        readonly={email_check ? true : false}
                        placeholder="이메일을 입력하세요."
                    />
                    <button
                        on:click={mail_send}
                        id="mail_send"
                        disabled={mail_send_yn ? "disabled" : ""}
                        style={mail_send_yn
                            ? "background: #E54031; color: white"
                            : ""}>{mail_send_text}</button
                    >
                </div>
                <div
                    class="join-info mail-confirm"
                    style="margin-top: 22px; {mail_confirm
                        ? 'display: flex'
                        : 'display: none'};"
                >
                    <p class="join-info-title"></p>
                    <div class="mail-timer">
                        <input
                            type="text"
                            bind:value={email_check_value}
                            bind:this={email_check_this}
                            id="email_check"
                            placeholder="인증번호"
                        />
                        <span id="timer">03:00</span>
                    </div>
                    <button on:click={fn_mail_confirm}>인증확인</button>
                </div>
                <div class="join-info filebox" style="margin-top: 22px;">
                    <p class="join-info-title">사업자등록증 첨부</p>
                    <input
                        bind:value={upload_name_1_value}
                        class="upload-name"
                        disabled="disabled"
                    />
                    <label for="byte_bsns_lcns">사진첨부</label>
                    <input
                        on:change={func_upload_1}
                        type="file"
                        id="byte_bsns_lcns"
                        class="upload-hidden"
                        accept="image/jpeg,image/png"
                    />
                </div>
                <div class="join-info filebox-2" style="margin-top: 22px;">
                    <p class="join-info-title">통장사본 첨부</p>
                    <input
                        bind:value={upload_name_2_value}
                        class="upload-name-2"
                        disabled="disabled"
                    />
                    <label for="byte_copy_acnt">사진첨부</label>
                    <input
                        on:change={func_upload_2}
                        type="file"
                        id="byte_copy_acnt"
                        class="upload-hidden-2"
                        accept="image/jpeg,image/png"
                    />
                </div>
                <div
                    class="cmn-btn cmn-btn-navy"
                    style="width: 221px; margin: 0 auto; margin-top: 72px;"
                >
                    <button on:click={join}>가입하기</button>
                </div>
            </div>
        </div>
    </div>
</section>
