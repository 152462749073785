<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import Header from "../../components/Header.svelte";
    import { push } from "svelte-spa-router";

    onMount(() => {
        if (Common.getCookie("orderList") == null) {
            alert("잘못된 접근입니다.");
            push("/as_home/home");
        }
        
        Common.deleteCookie("orderList");
    });
</script>

<Header />

<section class="main inner">
    <div class="mobile">
        <div class="cmn-title" style="margin-top: 114px">
            <p>주문등록 완료!</p>
        </div>
        <div class="join-wrap">
            <img
                src="resource/img/img_order_complete.png"
                alt=""
                class="m-join"
                style="width: 166px; height: 166px"
            />
        </div>
        <div class="join-info">
            <p>주문등록이 완료 되었습니다!</p>
            <p>감사합니다.</p>
        </div>
        <div class="cmn-btn" style="margin-top: 26px">
            <button
                on:click={() => {
                    push("/as_home/home");
                }}>HOME</button
            >
        </div>
    </div>

    <div class="pc main-pad">
        <div style="margin-top: 158px">
            <div class="cmn-title" style="font-weight: 600">
                <p>주문등록 완료!</p>
            </div>
            <div class="mail-wrap">
                <img
                    src="resource/img/img_join.png"
                    alt=""
                    class="m-mail"
                    style="width: 356px; height: 268px"
                />
            </div>
            <div class="mail-info">
                <p>
                    <span>주문등록이 완료 되었습니다!</span><br />
                    <span>감사합니다.</span><br />
                </p>
            </div>
            <div
                class="cmn-btn cmn-btn-navy"
                style="width: 424px; margin: 0 auto; margin-top: 39px"
            >
                <button
                    class="pw-find-btn"
                    on:click={() => {
                        push("/as_home/home");
                    }}
                >
                    메인화면 가기
                </button>
            </div>
        </div>
    </div>
</section>
