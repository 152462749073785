import * as api from "../apis/asCart.api";

export async function apiGetAsCartFindAll() {
    try {
        const response = await api.apiGetAsCartFindAll();

        if (response.status === 200) {
            if (response.data.code === 200) {
                return response.data.data;
            } else {
                return [];
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiPostAsCartUpdate(CART_ID, QNTT) {
    try {
        const formData = {
            CART_ID: CART_ID,
            QNTT: QNTT,
        };

        const response = await api.apiPostAsCartUpdate(formData);

        if (response.status === 200) {
            if (response.data.code === 200) {
                return true;
            } else {
                alert(response.data.msg);
                return false;
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiPostAsCartDelete(CART_ID) {
    try {
        let formData = [];

        if (typeof CART_ID == "object") {
            formData = {
                CART_ID_LIST: CART_ID,
            };
        } else {
            formData = {
                CART_ID: CART_ID,
            };
        }

        const response = await api.apiPostAsCartDelete(formData);

        if (response.status === 200) {
            if (response.data.code === 200) {
                return true;
            } else {
                alert(response.data.msg);
                return false;
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiGetAsCartOrder(CART_ID_LIST) {
    try {
        const formData = {
            CART_ID_LIST: CART_ID_LIST,
        };

        const response = await api.apiGetAsCartOrder(formData);

        if (response.status === 200) {
            if (response.data.code === 200) {
                return response.data.data;
            } else {
                return [];
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}
