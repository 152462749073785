<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import * as auth_api from "../../store/auth.store";
    import { push } from "svelte-spa-router";

    let pw_value = "";

    onMount(() => {
        // 로그인 상태 체크
        auth_api.apiGetStatus();

        if (Common.getCookie("BIZ_NO") == null) {
            alert("잘못된 접근입니다.");
            push("/login");
        }
    });

    // 비밀번호 입력 완료
    function pw_chk() {
        if (Common.nullCheck(pw_value, "pw", "비밀번호")) return;
        Common.setCookie("PW", pw_value, 1);
        push("/as_join/join_pw_reenter");
    }
</script>

<link rel="stylesheet" href="resource/css/common.css" />
<link rel="stylesheet" href="resource/css/style.css" />

<header class="header">
    <div class="hd-title inner">
        <div class="hd-title-wrap">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <img
                src="resource/img/ico_arrow_left.png"
                alt=""
                class="m-arrow-l"
                on:click={() => {
                    history.back();
                }}
            />
            <p class="hd-menu-nm">회원가입</p>
        </div>
    </div>
</header>

<section class="main inner">
    <div class="cmn-title" style="margin-top: 58px;">
        <p>회원가입</p>
    </div>
    <div class="cmn-input" style="margin-top: 40px;">
        <p>비밀번호</p>
        <input
            bind:value={pw_value}
            type="password"
            id="pw"
            placeholder="비밀번호를 입력해주세요."
            maxlength="20"
        />
    </div>
    <div class="cmn-btn" style="margin-top: 26px;">
        <button on:click={pw_chk}>확인</button>
    </div>
</section>
