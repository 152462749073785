import axios from "../utiles/axios.util";

function apiGetAsCartFindAll() {
    return axios.get(`/api/v1/asCart/findAll`);
}

function apiPostAsCartUpdate(formData) {
    return axios.post("/api/v1/asCart/update", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

function apiPostAsCartDelete(formData) {
    return axios.post("/api/v1/asCart/delete", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

function apiGetAsCartOrder(formData) {
    return axios.post("/api/v1/asCart/order", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });
}

export {
    apiGetAsCartFindAll,
    apiPostAsCartUpdate,
    apiPostAsCartDelete,
    apiGetAsCartOrder,
};
