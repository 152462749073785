<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import * as api from "../../store/asQuestion.store";
    import Header from "../../components/Header.svelte";
    import WebSideMenu from "../../components/WebSideMenu.svelte";
    import { push } from "svelte-spa-router";

    let sdate = "";
    let edate = "";

    // -------------------------------- PC --------------------------------
    let page = 1; // 현재페이지
    let scrollchk = true; // 스크롤 체크 여부 플래그
    let totallist = ""; // ajax로 받아온 list를 이 변수에 계속 누적시킴
    let mutex = false; // 중복호출 방지 플래그

    let qna_list = [];
    let qna_list_this;

    let qna_list_m = [];

    onMount(() => {
        var lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        var formattedLastMonth = lastMonth.toISOString().split("T")[0];
        sdate = formattedLastMonth;

        var today = new Date().toISOString().split("T")[0];
        edate = today;

        getPageData();
    });

    function itemListScroll() {
        if (scrollchk) {
            if (
                qna_list_this.scrollTop + 200 >=
                qna_list_this.scrollHeight - qna_list_this.clientHeight
            ) {
                if (mutex) {
                    return;
                }
                mutex = true;
                getPageData();
            }
        }
    }

    // 검색
    async function getPageData() {
        const response = await api.apiGetAsInquiryFindAll(page, sdate, edate);

        if (response.length == undefined) {
            page++; // 데이터를 받아오는데 성공했고 추가할 페이지가 있으면 현재 페이지를 늘려준다.

            qna_list = qna_list.concat(response.list);

            // 현재 페이지의 주소에 ## 이라는 앵커를 넣어줌으로서 이 페이지에 1회이상 페이지 로딩이 있었다는 표시를 해준다.
            // history.replaceState({list:totallist, page:page},'Page '+page, '/as_item/qna_list.html##')

            scrollchk = true; //데이터 로딩이 끝나면 스크롤체크를 풀어준다.
            mutex = false; //데이터로딩이 끝나면 ajax접근권한을 준다(중복호출 방어)
        }
    }

    // 달력 날짜 체크
    function checkDateRange() {
        if (!sdate || !edate) {
            alert("시작일과 종료일을 모두 선택해주세요.");
            return;
        }

        // 날짜를 Date 객체로 변환
        var startDateObj = new Date(sdate);
        var endDateObj = new Date(edate);

        if (startDateObj > endDateObj) {
            alert("시작일은 종료일보다 빨라야 합니다.");
            return;
        }
    }

    // 조회하기
    function date_search() {
        checkDateRange();

        qna_list = [];
        qna_list_this.scrollTop = 0;
        page = 1;

        getPageData();
    }

    // ------------------------------ Mobile ------------------------------
    let page_m = 1; // 현재페이지
    let scrollchk_m = true; // 스크롤 체크 여부 플래그
    let totallist_m = ""; // ajax로 받아온 list를 이 변수에 계속 누적시킴
    let mutex_m = false; // 중복호출 방지 플래그

    onMount(() => {
        getPageData_m();

        window.addEventListener("scroll", itemListScroll_m);
    });

    function itemListScroll_m() {
        var documentHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight
        );
        var windowHeight =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.getElementsByTagName("body")[0].clientHeight;

        if (scrollchk_m) {
            if (window.scrollY + 200 >= documentHeight - windowHeight) {
                if (mutex_m) {
                    return;
                }
                mutex_m = true;
                getPageData_m();
            }
        }
    }

    // 검색
    async function getPageData_m() {
        const response = await api.apiGetAsInquiryFindAll(page_m, sdate, edate);

        if (response.length == undefined) {
            page_m++; // 데이터를 받아오는데 성공했고 추가할 페이지가 있으면 현재 페이지를 늘려준다.

            qna_list_m = qna_list_m.concat(response.list);

            // 현재 페이지의 주소에 ## 이라는 앵커를 넣어줌으로서 이 페이지에 1회이상 페이지 로딩이 있었다는 표시를 해준다.
            // history.replaceState({list:totallist, page:page},'Page '+page, '/as_item/item_list.html##')

            scrollchk_m = true; //데이터 로딩이 끝나면 스크롤체크를 풀어준다.
            mutex_m = false; //데이터로딩이 끝나면 ajax접근권한을 준다(중복호출 방어)
        }
    }
</script>

<Header />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<section class="main inner main-pad-36">
    <div class="mobile">
        <div style="background: #FFF; padding-bottom: 16px;">
            <div class="cmn-title-left gray-width" style="padding-top: 20px;">
                <p>문의하기</p>
            </div>
            <div class="cmn-date gray-width" style="margin-top: 15px;">
                <input
                    bind:value={sdate}
                    type="date"
                    id="sdate_m"
                    placeholder="날짜를 선택해주세요."
                />
                <p>~</p>
                <input
                    bind:value={edate}
                    type="date"
                    id="edate_m"
                    placeholder="날짜를 선택해주세요."
                />
            </div>
            <div class="cmn-btn cmn-btn-red">
                <button on:click={date_search}>조회하기</button>
            </div>
        </div>
        <ul style="padding-bottom: 60px;" id="item-list_m">
            {#if qna_list_m.length > 0}
                {#each qna_list_m as data}
                    <li
                        class="qna-list"
                        on:click={() => {
                            push(`/as_question/qna_dtl?id=${data.inquiry_ID}`);
                        }}
                    >
                        <div class="qna-info" style="overflow: hidden;">
                            <p class="qna-info-title" style="width:60%">
                                {data.title}
                            </p>
                            <p class="qna-info-date">
                                {data.reg_DATE
                                    .replace(/\.\d+$/, "")
                                    .replaceAll("-", ".")}
                            </p>
                        </div>
                        {#if data.is_STATUS === "2"}
                            <div class="qna-btn-gray qna-btn-red">
                                <button
                                    >{data.is_STATUS === "1"
                                        ? "미답변"
                                        : data.is_STATUS === "2"
                                          ? "답변완료"
                                          : ""}</button
                                >
                            </div>
                        {:else}
                            <div class="qna-btn-gray">
                                <button
                                    >{data.is_STATUS === "1"
                                        ? "미답변"
                                        : data.is_STATUS === "2"
                                          ? "답변완료"
                                          : ""}</button
                                >
                            </div>
                        {/if}
                    </li>
                {/each}
            {:else}
                <p>검색 결과가 없습니다.</p>
            {/if}
        </ul>
        <div class="cmn-btn cmn-btn-btm">
            <button
                on:click={() => {
                    push("/as_question/qna_write");
                }}>문의하기</button
            >
        </div>
    </div>

    <div class="pc pc-inner">
        <WebSideMenu />

        <div
            class="pc-wh"
            style="width: calc(100% - 360px); margin-left: 370px; margin-top: 40px; margin-bottom: 20px;"
        >
            <div class="cmn-title" style="font-weight: 600; text-align: left;">
                <p>문의하기</p>
            </div>

            <div class="search-wrap" style="margin-top: 24px; height: 140px;">
                <div style="width: 95%; margin: 0 auto;">
                    <p class="search-title">기간조회</p>
                    <div class="cmn-date gray-width" style="margin-top: 7px;">
                        <input
                            bind:value={sdate}
                            type="date"
                            id="sdate"
                            placeholder="날짜를 선택해주세요."
                        />
                        <p>~</p>
                        <input
                            bind:value={edate}
                            type="date"
                            id="edate"
                            placeholder="날짜를 선택해주세요."
                        />
                        <div
                            class="cmn-btn cmn-btn-navy"
                            style="width: 128px; float: right; margin-top: 0px; margin-left: 20px;"
                        >
                            <button on:click={date_search}>검색</button>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <ul
                    bind:this={qna_list_this}
                    on:scroll={itemListScroll}
                    class="qna-list scroll-custom"
                    id="item-list"
                >
                    {#if qna_list.length > 0}
                        {#each qna_list as data}
                            <li>
                                <div
                                    class="qna-list-in"
                                    on:click={() => {
                                        push(
                                            `/as_question/qna_dtl?id=${data.inquiry_ID}`
                                        );
                                    }}
                                >
                                    <p class="qna-title">{data.title}</p>
                                    <p class="qna-date">
                                        {data.reg_DATE
                                            .replace(/\.\d+$/, "")
                                            .replaceAll("-", ".")}
                                    </p>
                                    {#if data.is_STATUS === "2"}
                                        <p class="qna-status qna-red">
                                            {data.is_STATUS === "1"
                                                ? "미답변"
                                                : data.is_STATUS === "2"
                                                  ? "답변완료"
                                                  : ""}
                                        </p>
                                    {:else}
                                        <p class="qna-status">
                                            {data.is_STATUS === "1"
                                                ? "미답변"
                                                : data.is_STATUS === "2"
                                                  ? "답변완료"
                                                  : ""}
                                        </p>
                                    {/if}
                                </div>
                            </li>
                        {/each}
                    {:else}
                        <p>검색 결과가 없습니다.</p>
                    {/if}
                </ul>
            </div>

            <div
                class="cmn-btn"
                style="position: relative; width: 1201px; display: flex;"
            >
                <div style="position: relative; width: 100%;">
                    <button
                        style="width: 284px; margin: 0 auto;"
                        on:click={() => {
                            push("/as_question/qna_write");
                        }}>문의하기</button
                    >
                </div>
            </div>
        </div>
    </div>
</section>
