import * as api from "../apis/asQuestion.api";

export async function apiGetAsInquiryFindAll(page, sdate, edate) {
    try {
        const response = await api.apiGetAsInquiryFindAll(page, sdate, edate);

        if (response.status === 200) {
            if (response.data.code === 200) {
                return response.data.data;
            } else {
                return [];
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiGetAsInquirySelect(INQUIRY_ID) {
    try {
        const response = await api.apiGetAsInquirySelect(INQUIRY_ID);

        if (response.status === 200) {
            if (response.data.code === 200) {
                return response.data.data;
            } else {
                return "";
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiPostAsInquiryDelete(INQUIRY_ID) {
    try {
        const formData = {
            INQUIRY_ID: INQUIRY_ID,
        };

        const response = await api.apiPostAsInquiryDelete(formData);

        if (response.status === 200) {
            alert(response.data.msg);

            if (response.data.code === 200) {
                return true;
            } else {
                return false;
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiPostAsInquiryInsert(formData) {
    try {
        const response = await api.apiPostAsInquiryInsert(formData);

        if (response.status === 200) {
            if (response.data.code === 200) {
                return true;
            } else {
                alert(response.data.msg);
                return false;
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}
