import JoinId from '../view/asJoin/JoinId.svelte';
import JoinPw from '../view/asJoin/JoinPw.svelte';
import JoinPwReenter from '../view/asJoin/JoinPwReenter.svelte';
import JoinMail from '../view/asJoin/JoinMail.svelte';
import JoinPhoto from '../view/asJoin/JoinPhoto.svelte';
import JoinComplete from '../view/asJoin/JoinComplete.svelte';

export const asJoinRoutes = {
    "/as_join/join_id": JoinId, // web 회원가입, mobile 회원가입 - 아이디 검사
    "/as_join/join_pw": JoinPw, // mobile 회원가입 - 비밀번호 검사
    "/as_join/join_pw_reenter": JoinPwReenter, // mobile 회원가입 - 비밀번호 재설정
    "/as_join/join_mail": JoinMail, // mobile 회원가입 - 이메일 검사
    "/as_join/join_photo": JoinPhoto, // mobile 회원가입 - 사업자등록증 및 통장사본 첨부
    "/as_join/join_complete": JoinComplete, // 회원가입 완료
};