<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import * as api from "../../store/auth.store";
    import { push } from "svelte-spa-router";

    let biz_no_value = "";
    let biz_no_this;

    let input_bizno = false;

    let email = "";

    let biz_no_m_value = "";
    let biz_no_m_this;

    onMount(() => {
        // 로그인 상태 체크
        api.apiGetStatus();
    });

    // --------------- PC ---------------
    // 비밀번호 찾기-(웹)
    async function pwFind() {
        if (Common.nullCheck(biz_no_value, "biz_no", "사업자등록번호")) return;

        if (biz_no_value.length != 10) {
            alert("사업자등록번호는 10자리 입니다.");
            biz_no_this.focus();
            return;
        }

        const regex = /^\d{10}$/;

        if (!regex.test(biz_no_value)) {
            alert("사업자등록번호는 숫자만 입력해주세요.");
            biz_no_this.focus();
            return;
        }

        email = await api.apiGetFindPw(biz_no_value);

        if (email !== "") {
            Common.setCookie("EMAIL", email, 1);
            push("/as_password/pw_send");
        } else {
            biz_no_this.focus();
            input_bizno = true;
        }
    }

    function formatInput(event) {
        biz_no_value = event.target.value.replace(/-/g, "");
    }

    // ------------- MOBILE -------------
    // 비밀번호 찾기-(모바일)
    async function pwFind_mobile() {
        if (Common.nullCheck(biz_no_m_value, "biz_no_m", "사업자등록번호"))
            return;

        if (biz_no_m_value.length != 10) {
            alert("사업자등록번호는 10자리 입니다.");
            biz_no_m_this.focus();
            return;
        }

        const regex = /^\d{10}$/;

        if (!regex.test(biz_no_m_value)) {
            alert("사업자등록번호는 숫자만 입력해주세요.");
            biz_no_m_this.focus();
            return;
        }

        email = await api.apiGetFindPw(biz_no_m_value);

        if (email !== "") {
            Common.setCookie("EMAIL", email, 1);
            push("/as_password/pw_send");
        } else {
            biz_no_m_this.focus();
            input_bizno = true;
        }
    }

    function formatInput_m(event) {
        biz_no_m_value = event.target.value.replace(/-/g, "");
    }
</script>

<link rel="stylesheet" href="resource/css/common.css" />
<link rel="stylesheet" href="resource/css/style.css" />

<header class="header">
    <div class="mobile hd-title inner">
        <div class="hd-title-wrap">
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <img
                src="resource/img/ico_arrow_left.png"
                alt=""
                class="m-arrow-l"
                on:click={() => {
                    push("/login");
                }}
            />
            <p class="hd-menu-nm">비밀번호 찾기</p>
        </div>
    </div>
    <div class="pc hd-inner">
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <img
            src="resource/img/img_logo.png"
            alt=""
            on:click={() => {
                push("/login");
            }}
        />
    </div>
</header>

<section class="main inner">
    <div class="mobile">
        <div class="cmn-title" style="margin-top: 58px;">
            <p>비밀번호 찾기</p>
        </div>
        <div class="cmn-input" style="margin-top: 40px;">
            <p>사업자등록번호</p>
            <input
                bind:value={biz_no_m_value}
                bind:this={biz_no_m_this}
                type="text"
                id="biz_no_m"
                on:input={formatInput_m}
                placeholder="사업자등록번호를 입력해주세요."
            />
        </div>
        <div class="none-info" style={input_bizno ? "display:block" : ""}>
            <div class="none-bizno">
                <p>존재하지 않는 사업자등록번호 입니다.</p>
            </div>
        </div>
        <div class="cmn-btn" style="margin-top: 26px;">
            <button class="pw-find-btn-err" on:click={pwFind_mobile}
                >비밀번호 찾기</button
            >
        </div>
    </div>

    <div class="pc main-pad">
        <div class="pw-find">
            <div>
                <div class="cmn-title" style="font-weight: 600;">
                    <p>비밀번호 찾기</p>
                </div>
                <div class="cmn-input" style="margin-top: 72px;">
                    <p>사업자등록번호</p>
                    <input
                        bind:value={biz_no_value}
                        bind:this={biz_no_this}
                        type="text"
                        id="biz_no"
                        on:input={formatInput}
                        placeholder="사업자등록번호를 입력해주세요."
                        class="input-bizno"
                        style={input_bizno
                            ? "border:1px solid var(--main-color, #E54031)"
                            : ""}
                    />
                </div>
                <div class="none-info">
                    <p>등록되지 않는 사업자등록번호 입니다!</p>
                </div>
                <div
                    class="cmn-btn cmn-btn-navy"
                    style="width: 424px; margin: 0 auto; margin-top: 22px;"
                >
                    <button on:click={pwFind}>비밀번호 찾기</button>
                </div>
            </div>
        </div>
    </div>
</section>
