import PwFind from '../view/asPassword/PwFind.svelte';
import PwSend from '../view/asPassword/PwSend.svelte';
import PwModify from '../view/asPassword/PwModify.svelte';
import PwEdit from '../view/asPassword/PwEdit.svelte';
import PwEditComplete from '../view/asPassword/PwEditComplete.svelte';

export const asPasswordRoutes = {
    "/as_password/pw_find": PwFind,
    "/as_password/pw_send": PwSend,
    "/as_password/pw_modify": PwModify,
    "/as_password/pw_edit": PwEdit,
    "/as_password/pw_edit_complete": PwEditComplete,
};