// 모바일 확인
export function isMobile() {
    var user = navigator.userAgent;
    var is_mobile = false;
    if (user.indexOf("iPhone") > -1 || user.indexOf("Android") > -1) {
        is_mobile = true;
    }
    return is_mobile;
}

// 쿠키 저장 함수
export function setCookie(name, value, days) {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = name + "=" + value + ";expires=" + expires.toUTCString();
}

// 쿠키 가져오기 함수
export function getCookie(name) {
    const cookieValue = document.cookie.match(
        "(^|;) ?" + name + "=([^;]*)(;|$)"
    );
    return cookieValue ? cookieValue[2] : null;
}

// 쿠키 삭제 함수
export function deleteCookie(name) {
    document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

// null 체크
export function nullCheck(data, id, name) {
    let result = false;
    if (!data.trim()) {
        alert(`${name}을(를) 입력해주세요.`);
        const inputElement = document.getElementById(id);

        if (inputElement) {
            inputElement.focus();
        }
        result = true;
    }
    return result;
}

// 이미지 변환
export function imgCnv(byt) {
    // Base64 디코딩
    var binaryData = atob(byt);

    // 이진 데이터를 Uint8Array로 변환
    var byteArray = new Uint8Array(binaryData.length);
    for (var j = 0; j < binaryData.length; j++) {
        byteArray[j] = binaryData.charCodeAt(j);
    }

    // Blob 생성
    var blob = new Blob([byteArray], { type: "image/png" });

    // Blob을 이미지로 변환
    var imageUrl = URL.createObjectURL(blob);
    return imageUrl;
}

// url에서 파라미너 가져오기
export function getURLParameters() {
    var paramMap = {};
    var url = window.location.href;
    var params = url.slice(url.indexOf("?") + 1).split("&");

    for (var i = 0; i < params.length; i++) {
        var pair = params[i].split("=");
        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1]);
        paramMap[key] = value;
    }

    return paramMap;
}

export function getFormatDate(d, str) {
    if (d == "") {
        var date = new Date();
    } else {
        var date = new Date(d);
    }
    var year = date.getFullYear(); //yyyy
    var month = 1 + date.getMonth(); //M
    month = month >= 10 ? month : "0" + month; //month 두자리로 저장
    var day = date.getDate(); //d
    day = day >= 10 ? day : "0" + day; //day 두자리로 저장
    return year + str + month + str + day;
}
