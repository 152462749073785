import * as asItem from "../apis/asItem.api";

export async function apiGetAsItemInit() {
    try {
        const response = await asItem.apiGetAsItemInit();

        if (response.status === 200) {
            if (response.data.code === 200) {
                return response.data.data;
            } else {
                return "";
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiGetAsItemInitMlsfcCode(LCLAS_CODE) {
    try {
        const response = await asItem.apiGetAsItemInitMlsfcCode(LCLAS_CODE);

        if (response.status === 200) {
            if (response.data.code === 200) {
                return response.data.data;
            } else {
                return [];
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiGetAsItemInitSclasCode(MLSFC_CODE) {
    try {
        const response = await asItem.apiGetAsItemInitSclasCode(MLSFC_CODE);

        if (response.status === 200) {
            if (response.data.code === 200) {
                return response.data.data;
            } else {
                return [];
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiGetAsItemFindAll(
    page,
    ITEM_NM,
    STNDRD,
    LCLAS_CODE,
    MLSFC_CODE,
    SCLAS_CODE
) {
    try {
        const response = await asItem.apiGetAsItemFindAll(
            page,
            ITEM_NM,
            STNDRD,
            LCLAS_CODE,
            MLSFC_CODE,
            SCLAS_CODE
        );

        if (response.status === 200) {
            if (response.data.code === 200) {
                return response.data.data;
            } else {
                return [];
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}

export async function apiPostAsItemCart(ITEM_CODE, QNTT) {
    try {
        const formData = {
            ITEM_CODE: ITEM_CODE,
            QNTT: QNTT,
        };
        const response = await asItem.apiPostAsItemCart(formData);

        if (response.status === 200) {
            if (response.data.code === 200) {
                alert(response.data.msg);
                return true;
            } else {
                return false;
            }
        } else {
            alert(
                "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
            );
        }
    } catch (e) {
        console.error("Error - store : ", e);
        alert(
            "네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다."
        );
    }
}
