import CartList from "../view/asCart/CartList.svelte";
import CartOrder from "../view/asCart/CartOrder.svelte";
import CartOrderComplete from "../view/asCart/CartOrderComplete.svelte";
import CartOrderList from "../view/asCart/CartOrderList.svelte";
import CartOrderListDtl from "../view/asCart/CartOrderListDtl.svelte";

export const asCartRoutes = {
    "/as_cart/cart_list": CartList,
    "/as_cart/cart_order": CartOrder,
    "/as_cart/cart_order_complete": CartOrderComplete,
    "/as_cart/cart_order_list": CartOrderList,
    "/as_cart/cart_order_list_dtl": CartOrderListDtl,
};