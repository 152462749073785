<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import { apiGetLogout } from "../../store/auth.store";
    import { push } from "svelte-spa-router";

    onMount(() => {
        if (Common.getCookie("pw_modify_end") == null) {
            alert("잘못된 접근입니다.");
            push("/login");
        }

        Common.deleteCookie("pw_modify_end");
    });

    async function logout() {
        await apiGetLogout();
    }
</script>

<link rel="stylesheet" href="resource/css/common.css" />
<link rel="stylesheet" href="resource/css/style.css" />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<header class="header">
    <div class="hd-title inner">
        <div class="mobile hd-title-wrap">
            <img
                src="resource/img/ico_arrow_left.png"
                alt=""
                class="m-arrow-l"
                on:click={() => {
                    history.back();
                }}
            />
            <p class="hd-menu-nm">비밀번호 수정</p>
        </div>
    </div>

    <div class="pc hd-inner">
        <img
            src="resource/img/img_logo.png"
            alt=""
            on:click={() => {
                push("/login");
            }}
        />
        <p
            on:click={() => {
                push("/as_cart/cart_list");
            }}
            style="margin-right: 100px; padding-right: 20px; border-right: 1px solid #67737C;"
        >
            장바구니
        </p>
        <p on:click={logout}>로그아웃</p>
    </div>
</header>

<section class="main inner">
    <div class="mobile">
        <div class="cmn-title" style="margin-top: 114px;">
            <p>비밀번호 수정 완료!</p>
        </div>
        <div class="join-wrap">
            <img src="resource/img/img_pw_edit.png" alt="" class="m-join" />
        </div>
        <div class="join-info">
            <p>비밀번호가 수정되었습니다!</p>
        </div>
        <div class="cmn-btn" style="margin-top: 26px;">
            <button
                on:click={() => {
                    push("/login");
                }}>HOME</button
            >
        </div>
    </div>

    <div class="pc main-pad">
        <div style="margin-top: 158px;">
            <div class="cmn-title" style="font-weight: 600;">
                <p>비밀번호 수정 완료!</p>
            </div>
            <div class="mail-wrap">
                <img
                    src="resource/img/img_join.png"
                    alt=""
                    class="m-mail"
                    style="width: 356px; height: 268px;"
                />
            </div>
            <div class="mail-info">
                <p>
                    <span>비밀번호가 정상적으로 수정되었습니다!</span>
                </p>
            </div>
            <div
                class="cmn-btn cmn-btn-navy"
                style="width: 424px; margin: 0 auto; margin-top: 39px;"
            >
                <button
                    on:click={() => {
                        push("/login");
                    }}>메인화면 가기</button
                >
            </div>
        </div>
    </div>
</section>
