<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import Header from "../../components/Header.svelte";

    let photo_FILE = "resource/img/img_item.png";

    onMount(() => {
        const param = Common.getURLParameters();

        if (param.photo_FILE != undefined) {
            photo_FILE = param.photo_FILE;
        }
    });
</script>

<Header />

<section class="main inner">
    <div class="item-dtl-img" style="margin-top: 20px;">
        <img id="item-dtl-img" src={photo_FILE} alt="" />
    </div>
</section>
