import axios from '../utiles/axios.util'

function apiGetStatus() {
    return axios.get(`/api/v1/auth/status`)
}

function apiPostlogin(formData) {
    return axios.post('/api/v1/auth/login', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}

function apiGetLogout() {
    return axios.get(`/api/v1/auth/logout`)
}

function apiGetFindPw(BIZ_NO) {
    return axios.get(`/api/v1/auth/find-pw?BIZ_NO=${BIZ_NO}`)
}

export { apiGetStatus, apiPostlogin, apiGetLogout, apiGetFindPw }
