<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import * as api from "../../store/auth.store";
    import { push } from "svelte-spa-router";

    let email_value = "";

    onMount(() => {
        // 로그인 상태 체크
        api.apiGetStatus();

        email_value = Common.getCookie("EMAIL");

        if (email_value == undefined) {
            alert("잘못된 접근입니다.");
            push("/login");
        }

        Common.deleteCookie("EMAIL");
    });
</script>

<link rel="stylesheet" href="resource/css/common.css" />
<link rel="stylesheet" href="resource/css/style.css" />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<header class="pc header">
    <div class="pc hd-inner">
        <img
            src="resource/img/img_logo.png"
            alt=""
            on:click={() => {
                push("/login");
            }}
        />
    </div>
</header>

<section class="main inner">
    <div class="mobile">
        <div class="mail-wrap">
            <img src="resource/img/img_mail.png" alt="" class="m-mail" />
        </div>
        <div class="mail-info">
            <p>
                <span class="mail-addr email">{email_value}</span><br />
                <span>비밀번호 재설정 메일이 발송되었습니다.</span><br />
                <span>전송된 임시비밀번호 로그인 후 </span><br />
                <span>반드시 비밀번호를 재설정 해주세요.</span><br />
            </p>
        </div>
        <div class="cmn-btn" style="margin-top: 47px;">
            <button
                on:click={() => {
                    push("/login");
                }}>메인화면 가기</button
            >
        </div>
    </div>

    <div class="pc main-pad">
        <div class="pw-find" style="height: 633px;">
            <div>
                <div class="cmn-title" style="font-weight: 600;">
                    <p>비밀번호 찾기 완료</p>
                </div>
                <div class="mail-wrap">
                    <img
                        src="resource/img/img_mail.png"
                        alt=""
                        class="m-mail"
                    />
                </div>
                <div class="mail-info">
                    <p>
                        <span class="mail-addr email">{email_value}</span><br />
                        <span>비밀번호 재설정 메일이 발송되었습니다.</span><br
                        />
                        <span>전송된 임시비밀번호 로그인 후 </span><br />
                        <span>반드시 비밀번호를 재설정 해주세요.</span><br />
                    </p>
                </div>
                <div
                    class="cmn-btn cmn-btn-navy"
                    style="width: 424px; margin: 0 auto; margin-top: 39px;"
                >
                    <button
                        on:click={() => {
                            push("/login");
                        }}>메인화면 가기</button
                    >
                </div>
            </div>
        </div>
    </div>
</section>
