import { writable } from "svelte/store"
import * as authApi from '../apis/auth.api'
import { push } from "svelte-spa-router";

export async function apiGetStatus() {
    try {
        const response = await authApi.apiGetStatus();

        if (response.status === 200) {
            if (response.data.code === 200) {
                if (location.href.includes('login')
                    || location.href.includes('as_join')
                    || location.href.includes('as_password')) {
                    push("/as_home/home")
                }
            } else {
                if (!location.href.includes('login')
                    && !location.href.includes('as_join')
                    && !location.href.includes('as_password')) {
                    push("/login")
                }
            }
        } else {
            alert('네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다.');
        }
    } catch (e) {
        console.error('Error - store : ', e);
        alert('네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다.');
    }
}

export async function apiPostlogin(BIZ_NO, PW) {
    try {
        const formData = {
            BIZ_NO: BIZ_NO,
            PW: PW,
        };

        const response = await authApi.apiPostlogin(formData);

        if (response.status === 200) {
            if (response.data.code === 200) {
                push("/as_home/home")
            } else {
                alert(response.data.msg);
            }
        } else {
            alert('네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다.');
        }
    } catch (e) {
        console.error('Error - store : ', e);
        alert('네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다.')
    }
}

export async function apiGetLogout() {
    try {
        const response = await authApi.apiGetLogout();

        if (response.status === 200) {
            if (response.data.code === 200) {
                push("/login")
            }
        } else {
            alert('네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다.');
        }
    } catch (e) {
        console.error('Error - store : ', e);
        alert('네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다.');
    }
}

export async function apiGetFindPw(BIZ_NO) {
    try {
        const response = await authApi.apiGetFindPw(BIZ_NO);

        if (response.status === 200) {
            if (response.data.code === 200) {
                return response.data.data;
            } else {
                alert(response.data.msg);
                return "";
            }
        } else {
            alert('네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다.');
        }
    } catch (e) {
        console.error('Error - store : ', e);
        alert('네트워크 장애로 인해 서버와 연결이 좋지 않아 정상적인 수행에 실패하였습니다.');
    }
}