<script>
    import { apiGetLogout } from "../store/auth.store";
    import { push } from "svelte-spa-router";

    async function logout() {
        await apiGetLogout();
    }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="pc-menu">
    <div style="width: 80%; margin: 0 auto;">
        <div class="pc-menu-in">
            <p>MENU</p>
            <img src="resource/img/ico_menu.png" class="img-menu" alt="" />
        </div>
        <ul>
            <li>
                <div
                    on:click={() => {
                        push("/as_item/item_list");
                    }}
                >
                    <img src="resource/img/ico_menu_1.png" alt="" />
                    <p>A/S 부품내역</p>
                </div>
            </li>
            <li>
                <div
                    on:click={() => {
                        push("/as_cart/cart_list");
                    }}
                >
                    <img src="resource/img/ico_menu_2.png" alt="" />
                    <p>A/S 부품 장바구니</p>
                </div>
            </li>
            <li>
                <div
                    on:click={() => {
                        push("/as_cart/cart_order_list");
                    }}
                >
                    <img src="resource/img/ico_menu_3.png" alt="" />
                    <p>주문내역</p>
                </div>
            </li>
            <li>
                <div
                    on:click={() => {
                        push("/as_question/qna_list");
                    }}
                >
                    <img src="resource/img/ico_menu_4.png" alt="" />
                    <p>문의내역</p>
                </div>
            </li>
            <li>
                <div
                    on:click={() => {
                        push("/as_password/pw_modify");
                    }}
                >
                    <img src="resource/img/ico_menu_5.png" alt="" />
                    <p>비밀번호 변경</p>
                </div>
            </li>
        </ul>

        <div class="logout" on:click={logout}>
            <img src="resource/img/img_logout.png" alt="" />
            <p>로그아웃</p>
        </div>
    </div>
</div>
