<script>
    import { onMount } from "svelte";
    import * as Common from "../../utiles/common.util";
    import * as api from "../../store/asCart.store";
    import * as orderApi from "../../store/asOrder.store";
    import Header from "../../components/Header.svelte";
    import WebSideMenu from "../../components/WebSideMenu.svelte";
    import { push } from "svelte-spa-router";

    let sdate = "";
    let edate = "";
    let is_status = "1";
    let get_order = [];
    let order_list = [];

    onMount(() => {
        var lastMonth = new Date();
        lastMonth.setMonth(lastMonth.getMonth() - 1);
        var formattedLastMonth = lastMonth.toISOString().split("T")[0];
        sdate = formattedLastMonth;

        var today = new Date().toISOString().split("T")[0];
        edate = today;

        order_status("status-1");
    });

    // ------------------------------ PC ------------------------------
    // 검색
    async function getPageData() {
        if (!sdate || !edate) {
            alert("시작일과 종료일을 모두 선택해주세요.");
            return;
        }

        // 날짜를 Date 객체로 변환
        var startDateObj = new Date(sdate);
        var endDateObj = new Date(edate);

        if (startDateObj > endDateObj) {
            alert("시작일은 종료일보다 빨라야 합니다.");
            return;
        }

        get_order = await orderApi.apiGetOrder(sdate, edate, is_status);
        order_list = get_order.orderList;
    }

    // 주문 상태별 조회
    function order_status(id) {
        var status = id.split("status-")[1];

        document.querySelectorAll(".red-txt").forEach((element) => {
            element.classList.remove("red-txt");
        });

        document.querySelectorAll(`.status${status}`).forEach((element) => {
            element.classList.add("red-txt");
        });

        document.querySelectorAll(".status").forEach((element) => {
            element.style.background = "#FFF";
            element.querySelectorAll("p").forEach((p) => {
                p.style.color = "#4A4A4A";
            });
        });

        document.getElementById(`status-m-${status}`).style.background =
            "#2D3C48";
        document
            .getElementById(`status-m-${status}`)
            .querySelectorAll("p")
            .forEach((p) => {
                p.style.color = "#FFF";
            });

        is_status = status;

        getPageData();
    }
</script>

<Header />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<section
    class="main inner main-pad-36 main-gray"
    style="width: 100%; position: relative;"
>
    <div class="mobile">
        <div style="background: #FFF; padding-bottom: 26px;">
            <div class="cmn-title-left gray-width" style="padding-top: 20px;">
                <p>A/S 부품 주문내역</p>
            </div>
            <div class="cmn-date gray-width" style="margin-top: 15px;">
                <input
                    bind:value={sdate}
                    type="date"
                    id="sdate_m"
                    placeholder="날짜를 선택해주세요."
                />
                <p>~</p>
                <input
                    bind:value={edate}
                    type="date"
                    id="edate_m"
                    placeholder="날짜를 선택해주세요."
                />
            </div>

            <div class="order-status gray-width" style="margin-top: 15px;">
                <img src="resource/img/img_line.png" alt="" class="img-line" />
                <div class="status-wrap">
                    <div
                        class="status"
                        style="margin-right: 4%;"
                        id="status-m-1"
                        on:click={() => {
                            order_status("status-1");
                        }}
                    >
                        <div>
                            <p>주문</p>
                            <p>등록</p>
                        </div>
                    </div>
                    <div
                        class="status"
                        style="margin-right: 4%;"
                        id="status-m-2"
                        on:click={() => {
                            order_status("status-2");
                        }}
                    >
                        <div>
                            <p>주문</p>
                            <p>확인</p>
                        </div>
                    </div>
                    <div
                        class="status"
                        style="margin-right: 4%;"
                        id="status-m-3"
                        on:click={() => {
                            order_status("status-3");
                        }}
                    >
                        <div>
                            <p>견적</p>
                            <p>확인</p>
                        </div>
                    </div>
                    <div
                        class="status"
                        id="status-m-4"
                        on:click={() => {
                            order_status("status-4");
                        }}
                    >
                        <div>
                            <p>출하</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="item-list_m">
            {#if order_list.length > 0}
                {#each order_list as data}
                    <div style="margin-top: 15px; background: #FFF;">
                        <div class="gray-width">
                            <div class="cart-order-list">
                                <div class="order-dtl">
                                    <p>
                                        {data.reg_DATE
                                            .replace(/\.\d+$/, "")
                                            .replaceAll("-", ".")}
                                    </p>
                                    <div
                                        class="order-dtl-in"
                                        on:click={() => {
                                            push(
                                                `/as_cart/cart_order_list_dtl?order_id=${data.order_ID}`
                                            );
                                        }}
                                    >
                                        <p>주문상세</p>
                                        <img
                                            src="resource/img/ico_arrow_right.png"
                                            alt=""
                                        />
                                    </div>
                                </div>
                                <ul>
                                    {#each get_order[`order_id_${data.order_ID}`] as dtilData}
                                        <li>
                                            <div class="list-in">
                                                <p class="list-title">품목명</p>
                                                <p>{dtilData.item_AS_NM}</p>
                                            </div>
                                            <div class="list-in">
                                                <p class="list-title">
                                                    품목코드
                                                </p>
                                                <p>{dtilData.item_CODE}</p>
                                            </div>
                                            <div class="list-in">
                                                <p class="list-title">규격</p>
                                                <p>
                                                    {dtilData.stndrd == null
                                                        ? ""
                                                        : dtilData.stndrd}
                                                </p>
                                            </div>
                                        </li>
                                    {/each}
                                </ul>
                            </div>
                        </div>
                    </div>
                {/each}
            {:else}
                <p>검색 결과가 없습니다.</p>
            {/if}
        </div>
    </div>

    <div class="pc pc-inner">
        <WebSideMenu />

        <div
            class="pc-wh"
            style="width: calc(100% - 360px); margin-left: 370px; margin-top: 40px; margin-bottom: 20px;"
        >
            <div class="cmn-title" style="font-weight: 600; text-align: left;">
                <p>A/S 부품 주문 내역</p>
            </div>

            <div class="order-status">
                <div
                    class="status-wrap"
                    style="border-top: 3px solid var(--main-color, #E54031);"
                    id="status-1"
                    on:click={() => {
                        order_status("status-1");
                    }}
                >
                    <div>
                        <img
                            src="resource/img/img_order_1.png"
                            alt=""
                            style="width: 137px; height: 107px;"
                        />
                        <p class="status1 red-txt">주문등록</p>
                    </div>
                </div>
                <div
                    style="height: 200px; display: flex; justify-content: center; align-items: center;"
                >
                    <img
                        src="resource/img/ico_circle.png"
                        alt=""
                        class="ico_circle"
                    />
                </div>
                <div
                    class="status-wrap"
                    style="border-top: 3px solid var(--main-color, #437EF7);"
                    id="status-2"
                    on:click={() => {
                        order_status("status-2");
                    }}
                >
                    <div>
                        <img
                            src="resource/img/img_order_2.png"
                            alt=""
                            style="width: 95.421px; height: 107px;"
                        />
                        <p class="status2">주문확인</p>
                    </div>
                </div>
                <div
                    style="height: 200px; display: flex; justify-content: center; align-items: center;"
                >
                    <img
                        src="resource/img/ico_circle.png"
                        alt=""
                        class="ico_circle"
                    />
                </div>
                <div
                    class="status-wrap"
                    style="border-top: 3px solid var(--main-color, #2D3C48);"
                    id="status-3"
                    on:click={() => {
                        order_status("status-3");
                    }}
                >
                    <div>
                        <img
                            src="resource/img/img_order_3.png"
                            alt=""
                            style="width: 118.459px; height: 107px;"
                        />
                        <p class="status3">견적 확인</p>
                    </div>
                </div>
                <div
                    style="height: 200px; display: flex; justify-content: center; align-items: center;"
                >
                    <img
                        src="resource/img/ico_circle.png"
                        alt=""
                        class="ico_circle"
                    />
                </div>
                <div
                    class="status-wrap"
                    style="border-top: 3px solid var(--main-color, #AFAFAF);"
                    id="status-4"
                    on:click={() => {
                        order_status("status-4");
                    }}
                >
                    <div>
                        <img
                            src="resource/img/img_order_4.png"
                            alt=""
                            style="width: 146.17px; height: 107px;"
                        />
                        <p class="status4">출하</p>
                    </div>
                </div>
            </div>

            <div class="search-wrap" style="margin-top: 24px; height: 140px;">
                <div style="width: 95%; margin: 0 auto;">
                    <p class="search-title">기간조회</p>
                    <div class="cmn-date gray-width" style="margin-top: 7px;">
                        <input
                            bind:value={sdate}
                            type="date"
                            id="sdate"
                            placeholder="날짜를 선택해주세요."
                        />
                        <p>~</p>
                        <input
                            bind:value={edate}
                            type="date"
                            id="edate"
                            placeholder="날짜를 선택해주세요."
                        />
                        <div
                            class="cmn-btn cmn-btn-navy"
                            style="width: 128px; float: right; margin-top: 0px; margin-left: 20px;"
                        >
                            <button on:click={getPageData}>검색</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="cart-list-wrap order-list-wrap">
                <div>
                    <ul
                        class="scroll-custom"
                        id="item-list"
                        style="margin-top: 10px; max-height: 357px; width: 1201px;"
                    >
                        {#if order_list.length > 0}
                            {#each order_list as data}
                                <li
                                    class="cart-list order-list"
                                    style="width: 1190px; display: grid;"
                                >
                                    <div class="order-date-wrap">
                                        <p>
                                            {data.reg_DATE
                                                .replace(/\.\d+$/, "")
                                                .replaceAll("-", ".")}
                                        </p>
                                        <p
                                            class="more-dtl"
                                            on:click={() => {
                                                push(
                                                    `/as_cart/cart_order_list_dtl?order_id=${data.order_ID}`
                                                );
                                            }}
                                        >
                                            주문내역 상세보기 <span
                                                ><img
                                                    src="resource/img/ico_more_dtl.png"
                                                    alt=""
                                                    style="width: 22px; height: 22px;"
                                                /></span
                                            >
                                        </p>
                                    </div>

                                    {#each get_order[`order_id_${data.order_ID}`] as dtilData}
                                        <div>
                                            <div
                                                class="item-dtl order-item-dtl"
                                            >
                                                <div class="item-img">
                                                    <img
                                                        src={dtilData.photo_FILE ==
                                                            null ||
                                                        dtilData.photo_FILE ==
                                                            ""
                                                            ? "resource/img/img_item.png"
                                                            : Common.imgCnv(
                                                                  dtilData.photo_FILE
                                                              )}
                                                        alt=""
                                                    />
                                                </div>
                                                <div
                                                    class="item-info cart-item-info"
                                                >
                                                    <p class="item-nm">
                                                        {dtilData.item_AS_NM}
                                                    </p>
                                                    <div class="item-info-wrap">
                                                        <p class="info-nm">
                                                            품목코드
                                                        </p>
                                                        <p
                                                            class="item-value item-code"
                                                        >
                                                            {dtilData.item_CODE}
                                                        </p>
                                                    </div>
                                                    <div class="item-info-wrap">
                                                        <p class="info-nm">
                                                            규격
                                                        </p>
                                                        <p
                                                            class="item-value item-stndrd"
                                                        >
                                                            {dtilData.stndrd ==
                                                            null
                                                                ? ""
                                                                : dtilData.stndrd}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/each}
                                </li>
                            {/each}
                        {:else}
                            <p>검색 결과가 없습니다.</p>
                        {/if}
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
